import React, { useState } from "react";
import "../../CSS/LoginPage.css";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import loginImage from "../../assets/images/loginPageImage.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const [active, setActive] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [loginError, setLoginError] = useState(false); // Add this line
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    setLoginError(false); // Reset login error state on each attempt
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL}/login`; // Use template literals

    try {
      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        localStorage.setItem("AdventureXtremeUserToken", result.token); // Store the access token
        navigate("/Dashboard"); // Redirect to dashboard or home page
      } else {
        const errorResult = await response.text(); // Get the text to see what was returned
        console.error("Error response body:", errorResult);
        throw new Error("Failed to login");
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginError(true); // Set login error state to true if there is an error
    }
  };

  const goToRegister = () => {
    navigate("/Register"); // Use the path you've defined for your LoginRegister component
  };

  const handleInputChange = (setter) => (event) => {
    const newValue = event.target.value;
    setter(newValue);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="loginRegisterPage">
      <div className="loginRegisterTitle">
        <span>Adventure Xtreme</span>
      </div>
      <div className="loginContainer">
        <div className="loginContainerGrid">
          <div className="loginBanner">
            <img src={loginImage} alt="" />
          </div>
          <div className="loginDetails">
            <h1>Login</h1>
            <div className="loginTextFields">
              <TextField
                label="Email Address"
                variant="outlined"
                required
                type="email"
                onChange={handleInputChange(setEmail)}
                sx={{
                  width: "70%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  paddingBottom: "3rem",
                  "& label": {
                    color: "white",
                  },
                  "& label.Mui-focused": {
                    color: "#7df9ff",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#7df9ff",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "#7df9ff",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#7df9ff",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    color: "white",
                  },
                }}
              />
              <TextField
                label="Password"
                variant="outlined"
                required
                type={showPassword ? "text" : "password"}
                onChange={handleInputChange(setPassword)}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                InputProps={{
                  style: { color: "#fff" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ color: "white" }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "70%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  paddingBottom: "2rem",
                  "& label": {
                    color: "white",
                  },
                  "& label.Mui-focused": {
                    color: "#7df9ff",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#7df9ff",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "#7df9ff",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#7df9ff",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    color: "white",
                  },
                }}
              />
              {loginError && (
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    paddingBottom: "1rem",
                  }}
                >
                  Error: Incorrect Email or Password
                </p>
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  handleLogin();
                }}
                sx={{
                  width: "50%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "transparent",
                  border: "2px solid #7df9ff",
                  borderRadius: "15px",
                  ":hover": {
                    borderColor: "red",
                    backgroundColor: "transparent",
                  },
                  transition: "transform 0.3s ease",
                }}
              >
                Sign In
              </Button>
              <p
                style={{
                  paddingTop: "1.5rem",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Not have an account? Sign up{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    color: "#7df9ff",
                    cursor: "pointer",
                  }}
                  onClick={goToRegister}
                >
                  here
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
