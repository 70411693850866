import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginRegister from "./LoginRegister";
import LoginPage from "./LoginPage";

import SignUpConsentForm from "./SignUpConsentForm";
import BuildYourProfile from "./BuildYourProfile";
import LandingPage from "./LandingPage";
import Register from "./Register";
import Dashboard from "./Dashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/LoginRegister" element={<LoginRegister />} />
        <Route path="/LoginPage" element={<LoginPage />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        {/* <Route path="/consent-form" element={<SignUpConsentForm />} /> */}
        {/*DELETE ALL BELOW WHEN FINISHED TESTING*/}
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}
export default App;
