import React, { useEffect, useState } from "react";
import "../../CSS/LandingPageTopNav.css";
import { useNavigate } from "react-router-dom";

function LandingPageTopNav() {
  const navigate = useNavigate();
  const [authOption, setAuthOption] = useState("Login");
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisible(false); // Start by fading out

      setTimeout(() => {
        // Change the text when the text is fully faded out (1.5s into the animation)
        setAuthOption((prevOption) =>
          prevOption === "Login" ? "Register" : "Login"
        );
        setVisible(true); // Then fade in
      }, 1500); // Halfway through the 3s interval
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const goToLoginRegister = () => {
    navigate("/LoginPage");
  };
  return (
    <div className="LandingPageTopNavComponent">
      <div className="topNavBar">
        <div className="optionOne">
          <p>Home</p>
        </div>
        <div className="optionTwo">
          <div className="linkOne">
            <p>Videos</p>
          </div>
          <div className="linkTwo">
            <p>Locations</p>
          </div>
          <div className="linkThree">
            <p>Sponsorships</p>
          </div>
          <div className="linkFour">
            <p>Community</p>
          </div>
        </div>
        <div className="optionThree">
          <p
            className={`authOption ${visible ? "fadeIn" : "fadeOut"}`}
            onClick={goToLoginRegister}
          >
            {authOption}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingPageTopNav;
