import React, { useState } from "react";
import { Checkbox, Button, FormControlLabel, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { sports } from "../../constants";
import "../../CSS/SportsChecklist.css";

const theme = createTheme({
  components: {
    // Name of the component
    MuiFormControlLabel: {
      styleOverrides: {
        // Name of the slot
        label: {
          // Some CSS
          fontFamily: "'Changa', sans-serif",
          color: "white",
        },
      },
    },
  },
});

const SportsChecklist = ({ selectedSports, onSelectSport }) => {
  const handleSelectSport = (sport) => {
    const newSelection = selectedSports.includes(sport)
      ? selectedSports.filter((s) => s !== sport)
      : [...selectedSports, sport];
    onSelectSport(newSelection); // Use function passed from the parent
  };

  const handleSportSubmit = () => {
    console.log("Selected Sports:", selectedSports);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          {sports.sort().map((sport, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSports.includes(sport)}
                    onChange={() => handleSelectSport(sport)}
                    name={sport}
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#7df9ff", // This changes the check mark color
                        "&:after": {
                          backgroundColor: "transparent", // This sets an overlay color
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "transparent", // Change as needed for hover state
                      },
                    }}
                  />
                }
                label={sport}
                sx={{
                  color: "white",
                  fontFamily: "'Changa', sans-serif",
                }}
              />
            </Grid>
          ))}
        </Grid>
        {/* <Button
          onClick={() => {
            handleSportSubmit();
          }}
          sx={{
            marginTop: "20px",
            left: "50%",
            color: "white",
            transform: "translateX(-50%)",
            border: "1px solid #7df9ff",
            transition: "0.3s ease-in-out",
            fontFamily: "'Changa', sans-serif",
            backgroundColor: "transparent",
            "&:hover": {
              transform: "scale(1.1) translateX(-45%)",
              transition: "0.3s ease-in-out",
            },
          }}
        >
          Submit
        </Button> */}
      </ThemeProvider>
    </div>
  );
};

export default SportsChecklist;
