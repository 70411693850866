import React, { useState, useEffect } from "react";
import MainNavBar from "../Components/MainNavBar";
import DashboardHome from "../Components/DashboardHome";
import { CgProfile } from "react-icons/cg";
import DashboardProfilePage from "../Components/DashboardProfilePage";
import DashboardDiscover from "../Components/DashboardDiscover";
import MyLocations from "../Components/MyLocations";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Grid,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import notification from "../../assets/images/notification.svg";
import notificationAlert from "../../assets/images/notificationAlert.svg";
import refresh from "../../assets/images/sync.svg";
import "../../CSS/Dashboard.css";
import { useNavigate } from "react-router-dom";
import SearchProfile from "../Components/SearchProfile";

const whiteTheme = createTheme({
  typography: {
    fontFamily: "'Changa', sans-serif",
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#fff",
          "&:focus": {
            backgroundColor: "#000b0c",
          },
        },
        icon: {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000b0c",
          ".MuiMenu-list": {
            border: "2px solid",
            borderColor: "#343434",
            borderRadius: "10px",
            fontFamily: "'Changa', sans-serif",
          },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#000b0c",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#343434",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#ffffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#343434",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#343434",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
        },
      },
    },
    // Adding styles for MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #343434",
          borderRadius: "15px",
        },
      },
    },
  },
});

function Dashboard() {
  const [selectedOption, setSelectedOption] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [detailedLocationRequests, setDetailedLocationRequests] = useState([]);
  const [viewedUsername, setViewedUsername] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("AdventureXtremeUserToken");

    if (token) {
      const decodeToken = (token) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (error) {
          return null;
        }
      };

      const decodedToken = decodeToken(token);
      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

      if (!decodedToken || decodedToken.exp < currentTime) {
        //console.log("Token is expired or invalid");
        setOpenDialog(true); // Redirect to login page
      } else {
        //console.log("Token is valid");
      }
    } else {
      //console.log("No token found");
      setOpenDialog(true);
    }
  }, [navigate]);

  const handleClose = () => {
    setOpenDialog(false);
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  // Function to change the selected option
  const handleSelectOption = (option, username = "") => {
    setSelectedOption(option);
    setViewedUsername(username);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Determine which component to render based on the selected option
  const renderSelectedOption = () => {
    switch (selectedOption) {
      case "Home":
        return <DashboardHome onNavigateToProfile={handleSelectOption} />;
      case "Profile":
        return (
          <DashboardProfilePage
            viewedUsername={viewedUsername}
            onNavigateToProfile={handleSelectOption}
          />
        );
      case "Spots":
        return <MyLocations onNavigateToProfile={handleSelectOption} />;
      case "Discover":
        return <DashboardDiscover onNavigateToProfile={handleSelectOption} />;
      case "Promo":
        return (
          <div
            style={{
              height: "90vh", // Make it take up the full viewport height
              display: "flex",
              flexDirection: "column", // Use column for vertical alignment
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 style={{ color: "white" }}>Coming Soon...</h1>
          </div>
        );
      case "H.O.F":
        return (
          <div
            style={{
              height: "90vh", // Make it take up the full viewport height
              display: "flex",
              flexDirection: "column", // Use column for vertical alignment
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 style={{ color: "white" }}>Coming Soon...</h1>
          </div>
        );
      case "Search":
        return <SearchProfile onNavigateToProfile={handleSelectOption} />;
      default:
        return <div>Page not found</div>;
    }
  };
  const fetchUserProfile = async (viewedUsername) => {
    const token = localStorage.getItem("AdventureXtremeUserToken");
    const url = viewedUsername
      ? `${process.env.REACT_APP_BACKEND_URL}/fetchUserProfile/${viewedUsername}`
      : `${process.env.REACT_APP_BACKEND_URL}/fetchUserProfile`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const data = await response.json();
      setUserData(data.profile);
      // Check if locationRequests exist and are not empty
      if (
        data.profile.locationRequests &&
        data.profile.locationRequests.length > 0
      ) {
        // Fetch detailed location requests
        await fetchLocationRequests(data.profile.locationRequests);
      } else {
        setDetailedLocationRequests([]);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchLocationRequests = async (locationRequestIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/fetchLocationRequestsDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ requestIds: locationRequestIds }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch location requests details");
      }

      const detailedRequests = await response.json();
      setDetailedLocationRequests(detailedRequests);
    } catch (error) {
      console.error("Error fetching location requests details:", error);
    }
  };

  useEffect(() => {
    fetchUserProfile("");
  }, []);

  const handleAccept = async (requestId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/locationRequestsAccept`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ requestId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to accept location request");
      }
      fetchUserProfile("");
      // Optionally refresh location requests or update UI accordingly
      console.log("Request accepted successfully");
      // Trigger a refresh of location requests or remove the accepted request from the UI here
    } catch (error) {
      console.error("Error accepting location request:", error);
    }
  };

  const handleDecline = async (requestId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/locationRequestsDecline`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ requestId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to decline location request");
      }
      fetchUserProfile("");
      // Optionally refresh location requests or update UI accordingly
      console.log("Request declined successfully");
      // Trigger a refresh of location requests or remove the declined request from the UI here
    } catch (error) {
      console.error("Error declining location request:", error);
    }
  };

  return (
    <div className="dashboardPage">
      <div className="sideNavContainer">
        <MainNavBar
          onSelectOption={handleSelectOption}
          selectedOption={selectedOption}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
        />
      </div>
      <div className={`dashboardTopBanner ${isMenuOpen ? "contentBlur" : ""}`}>
        <div className="notificationOption">
          <div>
            {userData &&
            userData.locationRequests &&
            userData.locationRequests.length > 0 ? (
              <img
                src={notificationAlert}
                alt="Notification Alert"
                className="notificationIcon"
                onClick={handleOpenDialog}
              />
            ) : (
              <img
                src={notification}
                alt="Notification"
                className="notificationIcon"
                onClick={handleOpenDialog}
              />
            )}
          </div>
          <div className="refreshButton">
            <img
              src={refresh}
              alt="Refresh"
              className="refreshIcon"
              onClick={() => fetchUserProfile("")}
            />
          </div>
        </div>
        <div className="dashboardPageTitle">
          <span>{selectedOption}</span>
        </div>
        <div
          className="profileOption"
          onClick={() => handleSelectOption("Profile", userData.username)}
        >
          <CgProfile className="profileIcon" />
        </div>
      </div>
      <div
        className={`selectedOptionContentContainer ${
          isMenuOpen ? "contentBlur" : ""
        }`}
      >
        {renderSelectedOption()}
      </div>

      <ThemeProvider theme={whiteTheme}>
        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <h2 style={{ textAlign: "center" }}>My Notifications:</h2>
          <DialogContent>
            <p style={{ textAlign: "center" }}>
              {" "}
              {userData && userData.locationRequests
                ? userData.locationRequests.length === 0
                  ? "You have 0 new notifications."
                  : `You have ${userData.locationRequests.length} new location request(s).`
                : "Loading notifications..."}{" "}
            </p>

            {detailedLocationRequests &&
              detailedLocationRequests.length > 0 && (
                <Grid container spacing={2} className="gridStyle">
                  {detailedLocationRequests.map((request, index) => (
                    <Grid item xs={12} key={index}>
                      <div
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                      >
                        <h2>Incoming Location:</h2>
                        <span>
                          <span style={{ fontWeight: "bold" }}>From: </span>
                          {request.senderUsername}
                        </span>
                        <br></br>
                        <span>
                          <span style={{ fontWeight: "bold" }}>
                            Location title:{" "}
                          </span>
                          {request.locationTitle}
                        </span>
                        <br></br>
                        <span>
                          <span style={{ fontWeight: "bold" }}>
                            Description:{" "}
                          </span>
                          {request.description}
                        </span>
                        <br></br>
                        <span>
                          <span style={{ fontWeight: "bold" }}>Sport: </span>
                          {request.sport}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAccept(request._id)}
                            className="requestAcceptButton"
                            sx={[
                              {
                                "&:hover": {
                                  color: "#343434",
                                  backgroundColor: "#000b0c",
                                },
                              },
                              { backgroundColor: "#000b0c", color: "white" },
                            ]}
                          >
                            Accept
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDecline(request._id)}
                            className="requestDeclineButton"
                            sx={[
                              {
                                "&:hover": {
                                  color: "white",
                                  backgroundColor: "red",
                                },
                              },
                              {
                                backgroundColor: "#000b0c",
                                color: "white",
                                border: "none",
                              },
                            ]}
                          >
                            Decline
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your session has timed out. Please log in or register again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}

export default Dashboard;
