import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { countries } from "countries-list";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [ageError, setAgeError] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [country, setCountry] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [usernameError, setUsernameError] = useState("");

  const [emailAvailable, setEmailAvailable] = useState(true);
  const [emailDuplicateError, setEmailDuplicateError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setEmailError(
      isValid ? "" : "Invalid email format (e.g., johndoe@example.com)."
    );
    return isValid;
  }

  function validatePassword(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    const isValid = passwordRegex.test(password);
    setPasswordError(
      isValid
        ? ""
        : "Password must be at least 8 characters long, include an uppercase letter, a special character, and a number"
    );
    return isValid;
  }
  function validateConfirmPassword(confirmedPassword) {
    if (confirmedPassword !== password) {
      setConfirmPasswordError("Passwords must match"); // This should set the confirmPasswordError
      return false;
    } else {
      setConfirmPasswordError(""); // Clear the confirmPasswordError if they do match
      return true;
    }
  }
  const validateAge = (date) => {
    if (!date) {
      setAgeError("Date of birth is required");
      return false;
    }

    const selectedDate = dayjs(date);
    const today = dayjs();
    const age = today.diff(selectedDate, "year");

    console.log("Testing selected date: " + selectedDate.format("YYYY-MM-DD"));
    console.log("Testing today date: " + today.format("YYYY-MM-DD"));
    console.log("Age: " + age);

    if (age < 18) {
      setAgeError("You must be at least 18 years old");
      return false;
    } else {
      setAgeError("");
      return true;
    }
  };

  const handleInputChange = (setter) => (event) => {
    const newValue = event.target.value;
    setter(newValue);
    if (username !== "") {
      handleUsernameDuplicate(newValue);
    }
  };

  const handleBlur = (validateFunction, fieldName) => () => {
    if (fieldName === "email") {
      validateEmail(email);
      handleEmailDuplicate(email);
    } else if (fieldName === "password") {
      validatePassword(password);
    } else if (fieldName === "confirmPassword") {
      validateConfirmPassword(confirmPassword);
    } else if (fieldName === "dateOfBirth") {
      validateAge(dateOfBirth);
    } else if (fieldName === "username") {
      handleUsernameDuplicate(username);
    }
  };

  const handleDateChange = (newValue) => {
    setDateOfBirth(newValue);
    validateAge(newValue);
  };
  async function handleEmailDuplicate(inputEmail) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/checkEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: inputEmail }), // Ensure this matches your backend's expected parameter
        }
      );

      const data = await response.json();
      if (response.ok) {
        if (!data.isAvailable) {
          // Username is taken
          setEmailAvailable(false);
          setEmailDuplicateError("Email is not available");
        } else if (data.isAvailable) {
          // Username is available
          setEmailAvailable(true);
          // Handle the "username available" case
        }
      } else {
        throw new Error(
          data.message || "An error occurred while checking the email."
        );
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  }

  async function handleUsernameDuplicate(inputUserName) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/checkUsername`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: inputUserName }), // Ensure this matches your backend's expected parameter
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (!data.isAvailable) {
          // Username is taken
          setUsernameAvailable(false);
          setUsernameError("Username is not available");
        } else if (data.isAvailable) {
          // Username is available
          setUsernameAvailable(true);
          // Handle the "username available" case
        }
      } else {
        throw new Error(
          data.message || "An error occurred while checking the username."
        );
      }
    } catch (error) {
      console.error("Error checking username:", error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const formData = {
      email: email,
      username: username,
      password: password, // In a real-world application, be cautious about logging passwords or sensitive information to the console.
      dateOfBirth: dateOfBirth ? dayjs(dateOfBirth).format("YYYY-MM-DD") : "",
      location: country, // Assuming 'country' holds the selected country's code from the dropdown
    };

    console.log("Form Data:", formData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        sessionStorage.setItem("userId", result.userId);
        console.log("userID: " + result.userId);
        const userId = sessionStorage.getItem("userId");
        console.log("RETRIEVED userID: " + userId);
        // Handle success - perhaps redirect to a new page or clear the form
      } else {
        throw new Error("Server responded with a non-200 status code");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle errors - show user a message, etc.
    }
  };
  const countriesArray = Object.keys(countries)
    .map((code) => ({
      code: code,
      name: countries[code].name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
  useEffect(() => {}, []);

  return (
    <form
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      onSubmit={handleSubmit}
    >
      <div
        className="oneByTwo"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
          width: "100%",
        }}
      >
        <div className="gridOne">
          <TextField
            label="Email Address"
            variant="outlined"
            required
            type="email"
            value={email}
            onChange={handleInputChange(setEmail)}
            onBlur={handleBlur(validateEmail, "email")}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: emailError || !emailAvailable ? "red" : "",
                },
                "&:hover fieldset": {
                  borderColor:
                    emailError || !emailAvailable
                      ? "red"
                      : "rgba(0, 0, 0, 0.23)", // Default hover border color
                },
              },
              "& .MuiInputLabel-root": {
                color: emailError || !emailAvailable ? "red" : "",
              },
            }}
          />
          {emailError && (
            <p style={{ color: "red", textAlign: "left" }}>{emailError}</p>
          )}
          {!emailAvailable && (
            <p style={{ color: "red", textAlign: "left" }}>
              {emailDuplicateError}
            </p>
          )}
        </div>
        <div className="gridTwo">
          <TextField
            label="Username"
            variant="outlined"
            required
            value={username}
            onChange={handleInputChange(setUsername)}
            onBlur={handleBlur(handleUsernameDuplicate, "username")}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: !usernameAvailable ? "red" : "",
                },
                "&:hover fieldset": {
                  borderColor: !usernameAvailable
                    ? "red"
                    : "rgba(0, 0, 0, 0.23)", // Default hover border color
                },
              },
              "& .MuiInputLabel-root": {
                color: !usernameAvailable ? "red" : "",
              },
              "& .Mui-focused .MuiInputLabel-root": {
                color: !usernameAvailable ? "red" : "",
              },
            }}
          />
          {!usernameAvailable && (
            <p style={{ color: "red", textAlign: "left" }}>{usernameError}</p>
          )}
        </div>
      </div>
      <div
        className="oneByTwo"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
          width: "100%",
        }}
      >
        <div className="gridOne">
          <TextField
            label="Password"
            variant="outlined"
            required
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handleInputChange(setPassword)}
            onBlur={handleBlur(validatePassword, "password")}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: passwordError ? "red" : "",
                },
                "&:hover fieldset": {
                  borderColor: passwordError ? "red" : "rgba(0, 0, 0, 0.23)",
                },
              },
              "& .MuiInputLabel-root": {
                color: passwordError ? "red" : "",
              },
            }}
            InputProps={{
              // Add the end adornment
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {passwordError && (
            <p style={{ color: "red", textAlign: "left" }}>{passwordError}</p>
          )}
        </div>
        <div className="gridTwo">
          <TextField
            label="Confirm Password"
            variant="outlined"
            required
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={handleInputChange(setConfirmPassword)}
            onBlur={handleBlur(validateConfirmPassword, "confirmPassword")}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: confirmPasswordError ? "red" : "",
                },
                "&:hover fieldset": {
                  borderColor: confirmPasswordError
                    ? "red"
                    : "rgba(0, 0, 0, 0.23)",
                },
              },
              "& .MuiInputLabel-root": {
                color: confirmPasswordError ? "red" : "",
              },
            }}
            InputProps={{
              // Add the end adornment
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword} // You can keep this as it is
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {confirmPasswordError && (
            <p style={{ color: "red", textAlign: "left" }}>
              {confirmPasswordError}
            </p>
          )}
        </div>
      </div>

      <div
        className="oneByTwo"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
          width: "100%",
        }}
      >
        <div className="gridOne">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date Of Birth *"
              value={dateOfBirth}
              onChange={handleDateChange}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: ageError ? "red" : "",
                  },
                  "&:hover fieldset": {
                    borderColor: ageError ? "red" : "rgba(0, 0, 0, 0.23)", // Default hover border color
                  },
                },
                "& .MuiInputLabel-root": {
                  color: ageError ? "red" : "",
                },
                "& .Mui-focused .MuiInputLabel-root": {
                  color: ageError ? "red" : "",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={handleDateChange}
                  onBlur={handleBlur(validateAge, "dateOfBirth")}
                  required
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: ageError ? "red" : "",
                      },
                      "&:hover fieldset": {
                        borderColor: ageError ? "red" : "rgba(0, 0, 0, 0.23)", // Default hover border color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: ageError ? "red" : "",
                    },
                    "& .Mui-focused .MuiInputLabel-root": {
                      color: ageError ? "red" : "",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          {ageError && (
            <p style={{ color: "red", textAlign: "left", marginTop: "8px" }}>
              {ageError}
            </p>
          )}
        </div>
        <div className="gridTwo">
          <FormControl fullWidth>
            <InputLabel id="country-select-label">Country *</InputLabel>
            <Select
              labelId="country-select-label"
              id="country-select"
              value={country}
              label="Country"
              required
              onChange={handleInputChange(setCountry)}
              sx={{ textAlign: "left" }}
            >
              {countriesArray.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        disabled={
          emailError !== "" ||
          passwordError !== "" ||
          confirmPasswordError !== "" ||
          ageError !== "" ||
          !email ||
          !password ||
          !confirmPassword ||
          !username ||
          !dateOfBirth ||
          !country
        }
      >
        Sign Up
      </Button>
    </form>
  );
};

export default SignUpForm;
