import React, { useState, useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import { IoLocationOutline } from "react-icons/io5";
import { countries } from "countries-list";
import { Button } from "@mui/material";
import { MdFileUpload } from "react-icons/md";
import SteppedProgressEditProfile from "../Components/SteppedProgressEditProfile";
import "../../CSS/DashboardProfilePage.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import CreatePost from "./CreatePost";
import UserProfilePosts from "./UserProfilePosts";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Settings from "../../assets/images/setting.svg";
import SettingsHover from "../../assets/images/settingHover.svg";
import ProfileEditDialog from "./ProfileEditDialog";
import UploadButton from "../../assets/images/upload.svg";
import BackArrow from "../../assets/images/backArrow.svg";
import BackArrowHover from "../../assets/images/backArrowHover.svg";
import { useNavigate } from "react-router-dom";

function DashboardProfilePage({ viewedUsername, onNavigateToProfile }) {
  const [view, setView] = useState("posts");
  const [userData, setUserData] = useState(null);
  const [followingFollowerData, setFollowingFollowerData] = useState(null);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  let [loggedInUsername, setLoggedInUsername] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState([]); // To store followers or following user data
  const [dialogTitle, setDialogTitle] = useState("");

  const [isHovering, setIsHovering] = useState(false);
  const [isProfilePicHovering, setIsProfilePicHovering] = useState(false);
  const [isEditProfileDialogOpen, setIsEditProfileDialogOpen] = useState(false);
  const [backArrowHover, setBackArrowHover] = useState(false);

  useEffect(() => {
    // Function to handle mouse enter
    const handleMouseEnter = () => setIsHovering(true);

    // Function to handle mouse leave
    const handleMouseLeave = () => setIsHovering(false);

    // Get the icon container element
    const iconContainer = document.querySelector(".settingsIconContainer");

    // Attach the event listeners
    if (iconContainer) {
      iconContainer.addEventListener("mouseenter", handleMouseEnter);
      iconContainer.addEventListener("mouseleave", handleMouseLeave);
    }

    // Cleanup the event listeners on component unmount
    return () => {
      if (iconContainer) {
        iconContainer.removeEventListener("mouseenter", handleMouseEnter);
        iconContainer.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const openEditProfile = () => setIsEditProfileDialogOpen(true);
  const closeEditProfile = () => setIsEditProfileDialogOpen(false);
  // Handler for opening dialog and setting its content

  const handleDialogOpen = async (listType) => {
    setOpenDialog(true);
    setDialogTitle(listType); // "Followers" or "Following"

    // Assuming fetchUsersByIDs is a function that fetches user details by IDs
    // And userData.followers or userData.following is an array of user IDs
    const userIds =
      listType === "Followers" ? userData.followers : userData.following;
    const usersDetails = await fetchUsersByIDs(userIds);
    setDialogContent(usersDetails);
  };

  const handleUsernameClick = (username) => {
    setOpenDialog(false);
    setDialogContent([]);
    if (onNavigateToProfile) onNavigateToProfile("Profile", username);
  };

  // Handler for closing dialog
  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogContent([]);
  };
  const isOwnProfile = () =>
    viewedUsername === loggedInUsername || viewedUsername === "";

  const openSocialMediaLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const toggleEditProfile = async () => {
    setIsEditProfileOpen(!isEditProfileOpen);
    if (isEditProfileOpen) {
      await fetchUserProfile();
    }
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  function CountryName({ countryCode }) {
    const countryName = countries[countryCode].name;

    return <span>{countryName}</span>;
  }

  const fetchUserProfile = async (viewedUsername) => {
    const token = localStorage.getItem("AdventureXtremeUserToken");
    const url = viewedUsername
      ? `${process.env.REACT_APP_BACKEND_URL}/fetchUserProfile/${viewedUsername}`
      : `${process.env.REACT_APP_BACKEND_URL}/fetchUserProfile`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const data = await response.json();
      setUserData(data.profile);
      setLoggedInUsername(data.isOwnProfile ? viewedUsername : null); // Adjust accordingly
      setIsFollowing(data.isFollowing); // Set isFollowing based on the new endpoint data
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  useEffect(() => {
    fetchUserProfile(viewedUsername);
  }, [viewedUsername]);

  const followUser = async (usernameToFollow) => {
    const token = localStorage.getItem("AdventureXtremeUserToken");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/follow/${usernameToFollow}`,
      {
        method: "POST", // or 'PUT' if you prefer
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    if (response.ok) {
      // Update state or UI accordingly
      fetchUserProfile();
    } else {
      throw new Error(data.error || "Failed to follow user");
    }
  };

  const unfollowUser = async (usernameToUnfollow) => {
    const token = localStorage.getItem("AdventureXtremeUserToken");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/unfollow/${usernameToUnfollow}`,
      {
        method: "POST", // or 'DELETE' if you prefer
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    if (response.ok) {
      // Update state or UI accordingly
      fetchUserProfile();
    } else {
      throw new Error(data.error || "Failed to unfollow user");
    }
  };
  const handleFollowClick = async () => {
    try {
      if (isFollowing) {
        await unfollowUser(viewedUsername); // Call the unfollow function
      } else {
        await followUser(viewedUsername); // Call the follow function
      }
      // Correctly calling fetchUserProfile to refresh data and correctly update isFollowing
      await fetchUserProfile(viewedUsername); // Ensure this fetches and updates state correctly
    } catch (error) {
      console.error(error.message); // Log any errors
    }
  };

  const fetchUsersByIDs = async (userIds) => {
    const token = localStorage.getItem("AdventureXtremeUserToken");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/fetchUsersByIds`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ userIds }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch users details");
    }

    const data = await response.json();
    setFollowingFollowerData(data);
    return data; // Array of user details
  };

  const handleUpdate = (updatedUserData) => {
    // Set the new username as the loggedInUsername
    setLoggedInUsername(updatedUserData.username);
  };

  if (!userData) {
    return <div>Loading profile...</div>;
  }

  const handleMouseEnter = () => {
    setIsProfilePicHovering(true);
  };

  const handleMouseLeave = () => {
    setIsProfilePicHovering(false);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profilePicture", file);

      try {
        const token = localStorage.getItem("AdventureXtremeUserToken");
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/updateProfilePicture`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );

        const result = await response.json();
        if (response.ok && result.success) {
          // Proceed only if response.ok and result indicates success
          console.log(result.ProfilePicture); // Log the success message from the server
          await fetchUserProfile(result.username); // Now safe to fetch the updated user profile
        } else {
          // Handle case where server response was not ok or didn't indicate success
          throw new Error(result.message || "Failed to update profile picture");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const whiteTheme = createTheme({
    typography: {
      fontFamily: "'Changa', sans-serif",
    },
    palette: {
      primary: {
        main: "#ffffff",
      },
      text: {
        primary: "#ffffff",
        secondary: "#ffffff",
      },
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            color: "#fff",
            "&:focus": {
              backgroundColor: "#000b0c",
            },
          },
          icon: {
            color: "#fff",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#000b0c",
            ".MuiMenu-list": {
              border: "2px solid",
              borderColor: "#343434",
              borderRadius: "10px",
              fontFamily: "'Changa', sans-serif",
            },
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#000b0c",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#343434",
              borderRadius: "5px",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderColor: "#ffffff",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            "&.Mui-selected": {
              backgroundColor: "#343434",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#343434",
              },
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: "16px",
          },
        },
      },
      // Adding styles for MuiDialog
      MuiDialog: {
        styleOverrides: {
          paper: {
            border: "5px solid #343434",
            borderRadius: "15px",
          },
        },
      },
    },
  });
  const handleBackButton = () => {
    if (onNavigateToProfile) onNavigateToProfile("Home");
  };
  return (
    <div>
      <div
        className="backButton"
        onMouseEnter={() => setBackArrowHover(true)}
        onMouseLeave={() => setBackArrowHover(false)}
        onClick={handleBackButton} // Assuming you want the whole area clickable
        style={{ cursor: "pointer" }}
      >
        {" "}
        {/* Ensure the cursor changes to pointer */}
        <img src={backArrowHover ? BackArrowHover : BackArrow} alt="Back" />
      </div>
      <div className="dashboardProfilePage">
        <div className="dashboardProfilePageContainer">
          <div className="dashboardProfilePageTopHalf">
            <div
              className="dashboardProfilePageImageContainer"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="profilePageImageWrapper">
                {userData.profilePicture ? (
                  <img
                    src={userData.profilePicture}
                    alt="Profile"
                    className="dashboardProfilePageImageIcon"
                  />
                ) : (
                  <CgProfile className="dashboardProfilePageImageIcon" />
                )}
                {isProfilePicHovering && (
                  <div className="uploadButtonOverlay">
                    <img src={UploadButton} className="uploadIcon" />
                    <input
                      type="file"
                      className="fileInput"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="dashboardProfilePageDetails">
              <div className="dashboardProfilePageDetailsWrapper">
                {isOwnProfile() && (
                  <div>
                    <div
                      className="settingsIconContainer"
                      onClick={openEditProfile}
                      onMouseEnter={() => setIsHovering(true)}
                      onMouseLeave={() => setIsHovering(false)}
                    >
                      <img
                        src={isHovering ? SettingsHover : Settings}
                        alt="Settings"
                        className="settingsIcon"
                      />
                    </div>
                    <ProfileEditDialog
                      open={isEditProfileDialogOpen}
                      handleClose={closeEditProfile}
                      userData={userData}
                      handleUpdate={handleUpdate}
                    />
                  </div>
                )}
                <div className="dashboardProfilePageDetailsUsername">
                  <span>
                    <strong>Username: </strong>
                    {userData.username}
                  </span>
                </div>
                <div className="dashboardProfilePageDetailsBio">
                  <span>
                    <strong>Bio: </strong>
                    {userData.bio}
                  </span>
                </div>
                <div className="dashboardProfilePageDetailsLocation">
                  <IoLocationOutline className="locationProfileIcon" />
                  <span> {userData.countryName}</span>
                </div>
                <div className="dashboardProfilePageDetailsSportInterests">
                  <span>
                    <strong>Sport Interests: </strong>
                  </span>
                  <div className="sportsInterestsGrid">
                    {userData.sportInterests.map((interest, index) => (
                      <span key={index}>•{interest}</span>
                    ))}
                  </div>
                </div>
                <div className="dashboardProfilePageFollowersFollowing">
                  <span
                    className="followersFollowingChoice"
                    onClick={() => handleDialogOpen("Followers")}
                  >
                    Followers: <br />
                    {userData.followers?.length || 0}
                  </span>
                  <span
                    className="followersFollowingChoice"
                    onClick={() => handleDialogOpen("Following")}
                  >
                    Following: <br />
                    {userData.following?.length || 0}
                  </span>
                  <ThemeProvider theme={whiteTheme}>
                    <Dialog
                      onClose={handleDialogClose}
                      open={openDialog}
                      PaperProps={{
                        style: {
                          width: "50vw",
                          maxHeight: "50vh",
                          minHeight: "50vh",
                          overflow: "auto",
                        },
                      }}
                    >
                      <DialogTitle style={{ textAlign: "center" }}>
                        {dialogTitle}
                      </DialogTitle>
                      <List>
                        {dialogContent.map((followingFollowerData) => (
                          <ListItem
                            key={followingFollowerData._id}
                            sx={{
                              height: "80px",
                              cursor: "pointer",
                              "& .MuiListItemAvatar-root": {
                                minWidth: "80px",
                              },
                              "& .MuiAvatar-root": {
                                width: "60px",
                                height: "60px",
                              },
                            }}
                            onClick={() => {
                              handleUsernameClick(
                                followingFollowerData.username
                              );
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={followingFollowerData.profilePicture}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={followingFollowerData.username}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Dialog>
                  </ThemeProvider>
                </div>
                {isOwnProfile() ? (
                  <div style={{ padding: "20px" }}></div>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                    onClick={handleFollowClick}
                  >
                    {isFollowing ? "Following" : "Follow"}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="socialMediaLinkGrid">
            <div className="socialMediaLinkIcon">
              <FaInstagram
                onClick={() =>
                  openSocialMediaLink(userData.socialMediaLinks.instagram)
                }
              />
            </div>
            <div className="socialMediaLinkIcon">
              <FaYoutube
                onClick={() =>
                  openSocialMediaLink(userData.socialMediaLinks.youtube)
                }
              />
            </div>
            <div className="socialMediaLinkIcon">
              <FaTiktok
                onClick={() =>
                  openSocialMediaLink(userData.socialMediaLinks.tiktok)
                }
              />
            </div>
          </div>
          <div className="dashboardProfilePageBottomHalf">
            <div className="dashboardProfilePageBottomHalfRibbon">
              <span
                className={`dashboardProfilePageBottomHalfRibbonOption ${
                  view === "posts" ? "selectedView" : "unselectedView"
                }`}
                onClick={() => handleViewChange("posts")}
              >
                POSTS
              </span>
              <span
                className={`dashboardProfilePageBottomHalfRibbonOption ${
                  view === "createPost" ? "selectedView" : "unselectedView"
                }`}
                onClick={() => handleViewChange("createPost")}
              >
                CREATE POST
              </span>
            </div>
            <div className="dashboardProfilePageBottomHalfPosts">
              {view === "posts" && (
                <div>
                  {/* Component or logic to display the user's posts */}
                  <div className="dashboardProfilePageBottomHalfPostsContainer">
                    <UserProfilePosts
                      viewedUsername={viewedUsername}
                      isOwnProfile={isOwnProfile()}
                      fetchUserPosts={() => fetchUserProfile(viewedUsername)} // Pass a function that wraps the call
                    />
                  </div>
                </div>
              )}
              {view === "createPost" && (
                <div>
                  {/* Component or form to create a new post */}
                  <div className="dashboardProfilePageBottomHalfPostsContainer">
                    <CreatePost />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardProfilePage;
