// SteppedProgress.jsx
import React, { useState, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { countries } from "countries-list";
import "../../CSS/SteppedProgress.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SignUpConsentForm from "../Components/SignUpConsentForm";
import blankProfile from "../../assets/images/blankProfile.png";
import SportsChecklist from "../Components/SportsChecklist";
import SocialMediaForm from "../Components/SocialMediaForm";
import { useNavigate } from "react-router-dom";

const SteppedProgress = () => {
  const [stepsComplete, setStepsComplete] = useState(0);
  const numSteps = 5;

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailAvailable, setEmailAvailable] = useState(true);
  const [emailDuplicateError, setEmailDuplicateError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [username, setUsername] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [usernameError, setUsernameError] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [ageError, setAgeError] = useState("");

  const [bio, setBio] = useState("");
  const [profilePic, setProfilePic] = useState(blankProfile); // Replace 'blankProfile' with the path to your initial profile picture.

  const [country, setCountry] = useState("");

  const [selectedSports, setSelectedSports] = useState([]);

  const [socialLinks, setSocialLinks] = useState({
    instagram: "",
    tiktok: "",
    youtube: "",
  });

  const navigate = useNavigate();
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const validateInstagramUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_\.]+/.test(url)
    );
  };

  const validateYoutubeUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?[a-zA-Z0-9\-_]+/.test(
        url
      )
    );
  };

  const validateTiktokUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?tiktok\.com\/@?[a-zA-Z0-9\-_]+/.test(url)
    );
  };

  const validateField = (name, value) => {
    switch (name) {
      case "instagram":
        return validateInstagramUrl(value);
      case "youtube":
        return validateYoutubeUrl(value);
      case "tiktok":
        return validateTiktokUrl(value);
      default:
        return true;
    }
  };

  const [socialErrors, setSocialErrors] = useState({
    instagram: false,
    tiktok: false,
    youtube: false,
  });

  // Function to update the error state, which you'll pass down to SocialMediaForm
  const updateSocialErrors = (errors) => {
    setSocialErrors(errors);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setProfilePic(e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setEmailError(
      isValid ? "" : "Invalid email format (e.g., johndoe@example.com)."
    );
    return isValid;
  }

  function validatePassword(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    const isValid = passwordRegex.test(password);
    setPasswordError(
      isValid
        ? ""
        : "Password must be at least 8 characters long, include an uppercase letter, a special character, and a number"
    );
    return isValid;
  }
  function validateConfirmPassword(confirmedPassword) {
    if (confirmedPassword !== password) {
      setConfirmPasswordError("Passwords must match");
      return false;
    } else {
      setConfirmPasswordError("");
      return true;
    }
  }
  const validateAge = (date) => {
    if (!date) {
      setAgeError("Date of birth is required");
      return false;
    }

    const selectedDate = dayjs(date);
    const today = dayjs();
    const age = today.diff(selectedDate, "year");

    if (age < 18) {
      setAgeError("You must be at least 18 years old");
      return false;
    } else {
      setAgeError("");
      return true;
    }
  };

  const handleInputChange = (setter) => (event) => {
    const newValue = event.target.value;
    setter(newValue);
    if (username !== "") {
      handleUsernameDuplicate(newValue);
    }
  };

  const handleBlur = (validateFunction, fieldName) => () => {
    if (fieldName === "email") {
      validateEmail(email);
      handleEmailDuplicate(email);
    } else if (fieldName === "password") {
      validatePassword(password);
    } else if (fieldName === "confirmPassword") {
      validateConfirmPassword(confirmPassword);
    } else if (fieldName === "dateOfBirth") {
      validateAge(dateOfBirth);
    } else if (fieldName === "username") {
      handleUsernameDuplicate(username);
    }
  };

  const handleDateChange = (newValue) => {
    setDateOfBirth(newValue);
    validateAge(newValue);
  };
  async function handleEmailDuplicate(inputEmail) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/checkEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: inputEmail }), // Ensure this matches your backend's expected parameter
        }
      );

      const data = await response.json();
      if (response.ok) {
        if (!data.isAvailable) {
          // Username is taken
          setEmailAvailable(false);
          setEmailDuplicateError("Email is not available");
        } else if (data.isAvailable) {
          // Username is available
          setEmailAvailable(true);
          // Handle the "username available" case
        }
      } else {
        throw new Error(
          data.message || "An error occurred while checking the email."
        );
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  }

  async function handleUsernameDuplicate(inputUserName) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/checkUsername`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: inputUserName }), // Ensure this matches your backend's expected parameter
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (!data.isAvailable) {
          setUsernameAvailable(false);
          setUsernameError("Username is not available");
        } else if (data.isAvailable) {
          setUsernameAvailable(true);
        }
      } else {
        throw new Error(
          data.message || "An error occurred while checking the username."
        );
      }
    } catch (error) {
      console.error("Error checking username:", error);
    }
  }

  const handleSubmit = async (event) => {
    const formData = {
      email: email,
      username: username,
      password: password,
      dateOfBirth: dateOfBirth ? dayjs(dateOfBirth).format("YYYY-MM-DD") : "",
      location: country,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        localStorage.setItem("AdventureXtremeUserToken", result.token); // Corrected from response.token to result.token
      } else {
        throw new Error("Server responded with a non-200 status code");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleAgreeToConsent = async () => {
    const token = localStorage.getItem("AdventureXtremeUserToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/agreeToConsentForm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        handleSetStep(1);
      } else {
        throw new Error("Server responded with a non-200 status code");
      }
    } catch (error) {
      console.error("Error submitting consent agreement:", error);
    }
  };

  const handleProfileSubmit = async (event) => {
    const file = document.querySelector('input[type="file"]').files[0]; // Adjust if your file input selector differs
    const bioText = bio; // Assuming 'bio' is the state variable holding the bio text

    const formData = new FormData();
    formData.append("profilePic", file);
    formData.append("bio", bioText);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/updateProfile`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "AdventureXtremeUserToken"
          )}`,
        },
        body: formData, // Send the form data
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("Server response:", result);
      // Handle success (e.g., notifying the user, updating the UI)
    } else {
      // Handle errors (e.g., displaying an error message)
      const error = await response.text();
      console.error("Error updating profile:", error);
    }
  };

  const handleSportChoices = async () => {
    console.log("Submitting selected sports: ", selectedSports);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/submitSportsChoices`,
        {
          // Adjust the URL as needed
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`, // Assuming you store the token in localStorage
          },
          body: JSON.stringify({ selectedSports }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Success! ", result.message);
      } else {
        throw new Error("Failed to submit sports choices");
      }
    } catch (error) {
      console.error("Error submitting sports choices:", error);
    }
  };

  const handleSubmitSocialMediaLinks = async () => {
    const token = localStorage.getItem("AdventureXtremeUserToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/updateSocialMediaLinks`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            instagram: socialLinks.instagram,
            tiktok: socialLinks.tiktok,
            youtube: socialLinks.youtube,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        // handle any post-update logic here, like navigating to another page or showing a success message
      } else {
        throw new Error("Server responded with a non-200 status code");
      }
    } catch (error) {
      console.error("Error updating social media links:", error);
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  const countriesArray = Object.keys(countries)
    .map((code) => ({
      code: code,
      name: countries[code].name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleSetStep = (num) => {
    if (
      (stepsComplete === 0 && num === -1) ||
      (stepsComplete === numSteps && num === 1)
    ) {
      return;
    }
    setStepsComplete((pv) => pv + num);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: "100%",
            "& label": {
              color: "#fff",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "#fff",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#fff",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: ageError ? "red" : "#fff",
              },
              "&:hover fieldset": {
                borderColor: ageError ? "red" : "#fff",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#fff",
              },
              "& input": {
                color: "#fff",
              },
              "& .MuiSvgIcon-root": {
                color: "#fff",
              },
            },
            "& .MuiInputLabel-root": {
              color: ageError ? "red" : "#fff",
            },
            "& .Mui-focused .MuiInputLabel-root": {
              color: ageError ? "red" : "#fff",
            },
          },
        },
        variants: [
          {
            props: { variant: "outlined", error: true },
            style: {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "red",
                },
              },
              "& .MuiInputLabel-root": {
                color: "red",
              },
            },
          },
        ],
      },
    },
  });
  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="steppedProgressPage">
      <div className="steppedProgressContainer">
        <Steps numSteps={numSteps} stepsComplete={stepsComplete} />

        <div className="steppedProgressCardArea">
          {stepsComplete === 0 && (
            <div>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div className="userDetailsForm">
                  <div style={{ width: "100%" }}>
                    <TextField
                      label="Email Address"
                      variant="outlined"
                      required
                      type="email"
                      value={email}
                      onChange={handleInputChange(setEmail)}
                      onBlur={handleBlur(validateEmail, "email")}
                      InputLabelProps={{
                        style: {
                          color: emailError || !emailAvailable ? "red" : "#fff",
                        },
                      }}
                      InputProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor:
                              emailError || !emailAvailable ? "red" : "#fff",
                          },
                          "&:hover fieldset": {
                            borderColor:
                              emailError || !emailAvailable ? "red" : "#fff",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor:
                              emailError || !emailAvailable ? "red" : "#fff",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: emailError || !emailAvailable ? "red" : "",
                        },
                        "& label.Mui-focused": {
                          color: emailError || !emailAvailable ? "red" : "#fff",
                        },
                      }}
                    />
                    {emailError && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {emailError}
                      </p>
                    )}
                    {!emailAvailable && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {emailDuplicateError}
                      </p>
                    )}
                  </div>
                  <div>
                    <TextField
                      label="Username"
                      variant="outlined"
                      required
                      value={username}
                      onChange={handleInputChange(setUsername)}
                      onBlur={handleBlur(handleUsernameDuplicate, "username")}
                      fullWidth
                      InputLabelProps={{
                        style: { color: !usernameAvailable ? "red" : "#fff" },
                      }}
                      InputProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: !usernameAvailable ? "red" : "#fff",
                          },
                          "&:hover fieldset": {
                            borderColor: !usernameAvailable ? "red" : "#fff",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: !usernameAvailable ? "red" : "#fff",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: !usernameAvailable ? "red" : "",
                        },
                        "& .Mui-focused .MuiInputLabel-root": {
                          color: "#fff",
                        },
                        "& label.Mui-focused": {
                          color: "#fff",
                        },
                      }}
                    />
                    {!usernameAvailable && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {usernameError}
                      </p>
                    )}
                  </div>
                  <div>
                    <TextField
                      label="Password"
                      variant="outlined"
                      required
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handleInputChange(setPassword)}
                      onBlur={handleBlur(validatePassword, "password")}
                      InputLabelProps={{
                        style: { color: passwordError ? "red" : "#fff" },
                      }}
                      InputProps={{
                        style: { color: "#fff" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              style={{ color: "white" }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: passwordError ? "red" : "#fff",
                          },
                          "&:hover fieldset": {
                            borderColor: passwordError ? "red" : "#fff",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: passwordError ? "red" : "#fff",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: passwordError ? "red" : "",
                        },
                        "& label.Mui-focused": {
                          color: "#fff",
                        },
                      }}
                    />
                    {passwordError && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {passwordError}
                      </p>
                    )}
                  </div>
                  <div>
                    <TextField
                      label="Confirm Password"
                      variant="outlined"
                      required
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={handleInputChange(setConfirmPassword)}
                      onBlur={handleBlur(
                        validateConfirmPassword,
                        "confirmPassword"
                      )}
                      InputLabelProps={{
                        style: { color: confirmPasswordError ? "red" : "#fff" },
                      }}
                      InputProps={{
                        style: { color: "#fff" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              style={{ color: "white" }}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: confirmPasswordError ? "red" : "#fff",
                          },
                          "&:hover fieldset": {
                            borderColor: confirmPasswordError ? "red" : "#fff",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: confirmPasswordError ? "red" : "#fff",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: confirmPasswordError ? "red" : "",
                        },
                        "& label.Mui-focused": {
                          color: "#fff",
                        },
                      }}
                    />
                    {confirmPasswordError && (
                      <p style={{ color: "red", textAlign: "left" }}>
                        {confirmPasswordError}
                      </p>
                    )}
                  </div>
                  <div>
                    <ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date Of Birth *"
                          value={dateOfBirth}
                          required
                          onChange={handleDateChange}
                          onBlur={handleBlur(validateAge, "dateOfBirth")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              onChange={handleDateChange}
                              onBlur={handleBlur(validateAge, "dateOfBirth")}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                    {ageError && (
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          marginTop: "8px",
                        }}
                      >
                        {ageError}
                      </p>
                    )}
                  </div>
                  <FormControl fullWidth>
                    <InputLabel
                      id="country-select-label"
                      sx={{
                        color: "#fff",
                        "&.Mui-focused": {
                          color: "#fff",
                        },
                      }}
                    >
                      Country *
                    </InputLabel>
                    <Select
                      labelId="country-select-label"
                      id="country-select"
                      value={country}
                      label="Country"
                      required
                      onChange={handleInputChange(setCountry)}
                      sx={{
                        textAlign: "left",
                        color: "#fff",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#fff",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#fff",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#fff",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#fff",
                        },
                      }}
                    >
                      {countriesArray.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </form>

              <div className="steppedProgressButtons">
                <Button
                  className="steppedProgressPrevButton"
                  type="submit"
                  onClick={() => {
                    handleSetStep(-1);
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    display: "none",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                  }}
                >
                  Prev
                </Button>
                <Button
                  className="steppedProgressNextButton"
                  onClick={() => {
                    handleSetStep(1);
                    handleSubmit();
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                    "&.Mui-disabled": {
                      borderColor: "grey", // Change the border color
                      color: "grey", // Change the text color
                      opacity: 0.5, // Optionally change the opacity
                    },
                  }}
                  disabled={
                    emailError !== "" ||
                    passwordError !== "" ||
                    confirmPasswordError !== "" ||
                    ageError !== "" ||
                    !email ||
                    !password ||
                    !confirmPassword ||
                    !username ||
                    !dateOfBirth ||
                    !country
                  }
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {stepsComplete === 1 && (
            <div>
              <div className="divB">
                <SignUpConsentForm />
                <div className="steppedProgressButtons">
                  <Button
                    className="steppedProgressPrevButton"
                    type="submit"
                    onClick={() => {
                      handleSetStep(-1);
                    }}
                    sx={{
                      color: "white",
                      border: "1px solid #7df9ff",
                      transition: "0.3s ease-in-out",
                      display: "none",
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "0.3s ease-in-out",
                      },
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    className="steppedProgressNextButton"
                    onClick={handleAgreeToConsent}
                    sx={{
                      color: "white",
                      border: "1px solid #7df9ff",
                      transition: "0.3s ease-in-out",
                      fontFamily: "'Changa',sans-serif",
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "0.3s ease-in-out",
                      },
                    }}
                  >
                    I agree, continue
                  </Button>
                </div>
              </div>
            </div>
          )}
          {stepsComplete === 2 && (
            <div id="divC">
              <div className="addPPandBiogrid">
                <div className="profilePic">
                  <div className="profilePicContainer">
                    <img src={profilePic} alt="" />
                  </div>
                  <div className="uploadButton">
                    <input
                      accept="image/*"
                      type="file"
                      id="select-image"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    <label htmlFor="select-image">
                      <Button
                        component="span"
                        sx={{
                          fontFamily: "'Changa', sans-serif",
                          textAlign: "center",
                          color: "white",
                          border: "1px solid #7df9ff",
                          transition: "0.3s ease-in-out",
                          left: "50%",
                          transform: "translateX(-50%)",
                          "&:hover": {
                            transform: "scale(1.1) translateX(-45%)",
                            transition: "0.3s ease-in-out",
                          },
                        }}
                      >
                        Choose From File
                      </Button>
                    </label>
                  </div>
                </div>
                <div className="bio">
                  <span>
                    <h1>Build Your Profile</h1>
                  </span>
                  <TextField
                    id="outlined-multiline-static"
                    label="Your Bio"
                    multiline
                    value={bio}
                    onChange={handleInputChange(setBio)}
                    rows={4}
                    variant="outlined"
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    InputProps={{
                      style: { color: "#fff" },
                    }}
                    sx={{
                      width: "80%",
                      left: "50%",
                      top: "10%",
                      transform: "translate(-50%,-10%)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#fff",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#fff",
                      },
                      "& .Mui-focused .MuiInputLabel-root": {
                        color: "#fff",
                      },
                      "& label.Mui-focused": {
                        color: "#fff",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="steppedProgressButtons">
                <Button
                  className="steppedProgressPrevButton"
                  type="submit"
                  onClick={() => {
                    handleSetStep(1);
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                  }}
                >
                  Skip
                </Button>
                <Button
                  className="steppedProgressNextButton"
                  onClick={() => {
                    handleSetStep(1);
                    handleProfileSubmit();
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                    "&.Mui-disabled": {
                      borderColor: "grey",
                      color: "grey",
                      opacity: 0.5,
                    },
                  }}
                  disabled={!profilePic || !bio}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {stepsComplete === 3 && (
            <div id="divD">
              <h2
                style={{
                  textAlign: "center",
                  color: "#7df9ff",
                  fontFamily: "'Changa', sans-serif",
                  fontWeight: "400",
                }}
              >
                Choose the sports that you're interested in!
              </h2>
              <SportsChecklist
                selectedSports={selectedSports}
                onSelectSport={setSelectedSports}
              />
              <div className="steppedProgressButtons">
                <Button
                  className="steppedProgressPrevButton"
                  type="submit"
                  onClick={() => {
                    handleSetStep(1);
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                  }}
                >
                  Skip
                </Button>
                <Button
                  className="steppedProgressNextButton"
                  onClick={() => {
                    handleSetStep(1);
                    handleSportChoices();
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                    "&.Mui-disabled": {
                      borderColor: "grey",
                      color: "grey",
                      opacity: 0.5,
                    },
                  }}
                  disabled={selectedSports.length === 0}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {stepsComplete === 4 && (
            <div className="SocialMediaForm">
              <SocialMediaForm
                socialLinks={socialLinks}
                setSocialLinks={setSocialLinks}
                validateField={validateField}
                updateErrors={updateSocialErrors} // Passing the function to update parent's state
              />
              <div className="steppedProgressButtons">
                <Button
                  className="steppedProgressPrevButton"
                  type="submit"
                  onClick={() => {
                    handleSetStep(1);
                    navigate("/Dashboard");
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                  }}
                >
                  Skip
                </Button>
                <Button
                  className="steppedProgressNextButton"
                  onClick={() => {
                    handleSetStep(1);
                    handleSubmitSocialMediaLinks();
                    navigate("/Dashboard");
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                    "&.Mui-disabled": {
                      borderColor: "grey",
                      color: "grey",
                      opacity: 0.5,
                    },
                  }}
                  disabled={
                    (!socialLinks.instagram &&
                      !socialLinks.tiktok &&
                      !socialLinks.youtube) ||
                    Object.values(socialErrors).some(Boolean)
                  }
                >
                  Finish
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Steps = ({ numSteps, stepsComplete }) => {
  const stepArray = Array.from(Array(numSteps).keys());

  return (
    <div className="stepsContainer">
      {stepArray.map((num) => {
        const stepNum = num + 1;
        const isActive = stepNum <= stepsComplete;
        return (
          <React.Fragment key={stepNum}>
            <Step num={stepNum} isActive={isActive} />
            {stepNum !== numSteps && (
              <div className="stepsContent">
                <motion.div
                  className="stepsMotion"
                  initial={{ width: 0 }}
                  animate={{ width: isActive ? "100%" : 0 }}
                  transition={{ ease: "easeIn", duration: 0.3 }}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Step = ({ num, isActive }) => {
  return (
    <div className="stepContainer">
      <div
        className={`stepCircleArea ${
          isActive ? "stepCircleAreaIsActive" : "stepCircleAreaNotActive"
        }`}
      >
        <AnimatePresence mode="wait">
          {isActive ? (
            <motion.svg
              key="icon-marker-check"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="stepAnimationClass"
            >
              <path
                fillRule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </motion.svg>
          ) : (
            <motion.span
              key="icon-marker-num"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="stepAnimationClass"
            >
              {num}
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      {isActive && <div className="pulse"></div>}
    </div>
  );
};

export default SteppedProgress;
