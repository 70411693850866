import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Chip,
  Typography,
  Box,
  Divider,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { countries } from "countries-list";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { sports } from "../../constants";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";

const whiteTheme = createTheme({
  typography: {
    fontFamily: "'Changa', sans-serif",
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#fff",
          "&:focus": {
            backgroundColor: "#000b0c",
          },
        },
        icon: {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000b0c",
          ".MuiMenu-list": {
            border: "2px solid",
            borderColor: "#343434",
            borderRadius: "10px",
            fontFamily: "'Changa', sans-serif",
          },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#000b0c",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#343434",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#ffffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#343434",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#343434",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
        },
      },
    },
    // Adding styles for MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #343434",
          borderRadius: "15px",
        },
      },
    },
  },
});

const ProfileEditDialog = ({ open, handleClose, userData, handleUpdate }) => {
  const [username, setUsername] = useState(userData.username);
  const [bio, setBio] = useState(userData.bio);
  const [instagram, setInstagram] = useState(
    userData.socialMediaLinks?.instagram || ""
  );
  const [tiktok, setTiktok] = useState(userData.socialMediaLinks?.tiktok || "");
  const [youtube, setYoutube] = useState(
    userData.socialMediaLinks?.youtube || ""
  );
  const [selectedSports, setSelectedSports] = useState(
    userData.sportInterests || []
  );
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const navigate = useNavigate();
  const countriesArray = Object.keys(countries)
    .map((code) => ({
      code: code,
      name: countries[code].name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
  const getCountryCodeByName = (name) => {
    const country = countriesArray.find((country) => country.name === name);
    return country ? country.code : null;
  };

  const countryCode = getCountryCodeByName(userData.countryName);
  const [country, setCountry] = useState(countryCode || "");

  const [errors, setErrors] = useState({
    instagram: false,
    youtube: false,
    tiktok: false,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const handleInputChange = (valueSetter) => (event) => {
    valueSetter(event.target.value);
  };

  const handleSportChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSports(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLogout = () => {
    localStorage.removeItem("AdventureXtremeUserToken"); // Clear the token
    setOpenLogoutDialog(true); // Open the logout confirmation dialog
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
    navigate("/"); // Redirect to the login page after closing the dialog
  };

  const handleSubmit = async () => {
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      // Display dialog box here
      setOpenDialog(true); // Assuming you have a state to control the dialog visibility
      return; // Stop execution of the rest of the function
    }

    const updatedProfileData = {
      username: username,
      bio: bio,
      socialMediaLinks: {
        instagram: instagram,
        tiktok: tiktok,
        youtube: youtube,
      },
      sportInterests: selectedSports,
      location: country, // Assuming `country` holds the country code
    };

    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("AdventureXtremeUserToken");

      // Make a PUT request to the backend to update the user profile
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/updateUserProfile`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProfileData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Profile updated successfully:", result);
        handleUpdate(result.updatedUser);
      } else {
        throw new Error(
          "Server responded with a non-200 status code while updating profile"
        );
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Here, handle the error - maybe show an error message to the user
    }
    handleClose();
  };

  const validateInstagramUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_\.]+/.test(url)
    );
  };

  const validateYoutubeUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?[a-zA-Z0-9\-_]+/.test(
        url
      )
    );
  };

  const validateTiktokUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?tiktok\.com\/@?[a-zA-Z0-9\-_]+/.test(url)
    );
  };

  const validateField = (name, value) => {
    switch (name) {
      case "instagram":
        return validateInstagramUrl(value);
      case "youtube":
        return validateYoutubeUrl(value);
      case "tiktok":
        return validateTiktokUrl(value);
      default:
        return true;
    }
  };
  const handleSocialsChange = (event) => {
    const { name, value } = event.target;

    // Validate and set errors
    const isValid = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));

    // Update the corresponding field's state
    switch (name) {
      case "instagram":
        setInstagram(value);
        break;
      case "youtube":
        setYoutube(value);
        break;
      case "tiktok":
        setTiktok(value);
        break;
      default:
        break; // No default action
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteAccount = async () => {
    setIsSubmitting(true);

    try {
      const token = localStorage.getItem("AdventureXtremeUserToken");
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/deleteAccount`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result.message);

      // Handle successful account deletion here:
      // - You might want to redirect the user to the login page
      // - Or show a message that their account has been deleted
      // - And clear any user data from the state/context
      navigate("/");
    } catch (error) {
      console.error("Failed to delete account:", error);
      // Handle errors here
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  return (
    <ThemeProvider theme={whiteTheme}>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Edit Profile
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* Username and Bio Section */}
          <Box>
            <Tooltip
              title="You can't change your username"
              placement="top"
              arrow
            >
              <span>
                {" "}
                {/* Wrap in a span because Tooltip children must be able to accept a ref */}
                <TextField
                  margin="dense"
                  id="username"
                  label="Username"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={username}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "white", // Your text color
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "grey", // Your border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", // Border color on hover
                    },
                  }}
                />
              </span>
            </Tooltip>
            <TextField
              margin="dense"
              id="bio"
              label="Bio"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={bio}
              onChange={handleInputChange(setBio)}
            />
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Location
            </Typography>
            <FormControl fullWidth margin="dense">
              <InputLabel id="country-select-label">Country</InputLabel>
              <Select
                labelId="country-select-label"
                id="country-select"
                value={country}
                label="Country"
                onChange={handleInputChange(setCountry)}
              >
                {countriesArray.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider sx={{ my: 2 }} /> {/* Margin top & bottom */}
          {/* Sport Interests Section */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Sport Interests
            </Typography>
            <FormControl fullWidth margin="dense">
              <InputLabel id="sports-select-label">Sport Interests</InputLabel>
              <Select
                labelId="sports-select-label"
                id="sports-select"
                multiple
                value={selectedSports}
                onChange={handleSportChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Sport Interests"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{ marginBottom: "5px" }}
                      />
                    ))}
                  </Box>
                )}
              >
                {sports.map((sport) => (
                  <MenuItem
                    key={sport}
                    value={sport}
                    // Optional: Use if you want to customize the selected items
                    // style={getStyles(name, personName, theme)}
                  >
                    {sport}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider sx={{ my: 2 }} /> {/* Margin top & bottom */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Social Media Links
            </Typography>
            <TextField
              margin="dense"
              id="instagram"
              name="instagram"
              label="Instagram"
              type="text"
              fullWidth
              variant="outlined"
              value={instagram}
              onChange={handleSocialsChange}
              error={errors.instagram}
            />
            {errors.instagram && (
              <p style={{ color: "red" }}>This Instagram URL is not valid.</p>
            )}
            <TextField
              margin="dense"
              id="youtube"
              name="youtube"
              label="YouTube"
              type="text"
              fullWidth
              variant="outlined"
              value={youtube}
              onChange={handleSocialsChange}
              error={errors.youtube}
            />
            {errors.youtube && (
              <p style={{ color: "red" }}>This YouTube URL is not valid.</p>
            )}
            <TextField
              margin="dense"
              id="tiktok"
              name="tiktok"
              label="TikTok"
              type="text"
              fullWidth
              variant="outlined"
              value={tiktok}
              onChange={handleSocialsChange}
              error={errors.tiktok}
            />
            {errors.tiktok && (
              <p style={{ color: "red" }}>This TikTok URL is not valid.</p>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#000b0c",
                border: "2px solid #343434",
                transition: "0.3s ease",
                "&:hover": {
                  backgroundColor: "#343434", // Darken the button when hovered
                  borderColor: "#343434",
                },
              }}
            >
              Save Changes
            </Button>
          </Box>
          <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
            <Button
              variant="outlined"
              onClick={handleLogout}
              sx={{
                color: "white",
                border: "2px solid #343434",
                transition: "0.3s ease",
                "&:hover": {
                  backgroundColor: "#343434", // Darken the button when hovered
                  borderColor: "#343434",
                },
              }}
            >
              Log Out
            </Button>
            <Button
              variant="outlined"
              onClick={handleDeleteAccount}
              sx={{
                color: "red",
                border: "2px solid red",
                transition: "0.3s ease",
                "&:hover": {
                  backgroundColor: "white", // Darken the button when hovered
                },
              }}
            >
              Delete Account
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">{"Logged Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            You have been successfully logged out.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fix the errors before you proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ProfileEditDialog;
