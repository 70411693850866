import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import "../../CSS/CreatePost.css";
import CircularProgress from "@mui/material/CircularProgress";
import VideoThumbnail from "./VideoThumbnail";
import { sports } from "../../constants";
import { createTheme } from "@mui/material/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
const whiteTheme = createTheme({
  typography: {
    fontFamily: "'Changa', sans-serif",
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#fff",
          "&:focus": {
            backgroundColor: "#000b0c",
          },
        },
        icon: {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000b0c",
          ".MuiMenu-list": {
            border: "2px solid",
            borderColor: "#343434",
            borderRadius: "10px",
            fontFamily: "'Changa', sans-serif",
          },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#000b0c",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#343434",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#ffffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#343434",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#343434",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
        },
      },
    },
    // Adding styles for MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #343434",
          borderRadius: "15px",
        },
      },
    },
  },
});
function CreatePost() {
  const [video, setVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [caption, setCaption] = useState("");
  const [sport, setSport] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!video) {
      setThumbnail(null);
      if (thumbnailUrl) {
        URL.revokeObjectURL(thumbnailUrl);
        setThumbnailUrl(null);
      }
      return;
    }

    const videoElement = document.createElement("video");
    const canvasElement = document.createElement("canvas");

    const generateThumbnail = () => {
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;
      canvasElement
        .getContext("2d")
        .drawImage(
          videoElement,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
      canvasElement.toBlob((blob) => {
        const thumbnailFile = new File([blob], "thumbnail.jpg", {
          type: "image/jpeg",
        });
        setThumbnail(thumbnailFile);

        if (thumbnailUrl) {
          URL.revokeObjectURL(thumbnailUrl);
        }
        const url = URL.createObjectURL(thumbnailFile);
        setThumbnailUrl(url);
      }, "image/jpeg");
    };

    videoElement.src = URL.createObjectURL(video);
    videoElement.addEventListener("loadedmetadata", () => {
      videoElement.currentTime = videoElement.duration / 2;
    });
    videoElement.addEventListener("seeked", generateThumbnail, { once: true });

    return () => {
      videoElement.pause();
      videoElement.removeAttribute("src");
      videoElement.load();
      if (thumbnailUrl) {
        URL.revokeObjectURL(thumbnailUrl);
      }
    };
  }, [video]);

  const handlePostSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("video", video);
    if (thumbnail) formData.append("thumbnail", thumbnail); // Append thumbnail if available
    formData.append("caption", caption);
    formData.append("sport", sport);
    formData.append("difficulty", difficulty);
    formData.append("location", location);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/createPost`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        setVideo(null);
        setThumbnail(null);
        setCaption("");
        setSport("");
        setDifficulty(1);
        setLocation("");
      } else {
        const error = await response.text();
        console.error("Error creating post:", error);
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const sportsSorted = sports.sort();
  return (
    <ThemeProvider theme={whiteTheme}>
      <Box
        className="createPostContainer"
        sx={{
          "& .MuiTextField-root": {
            m: 1,
          },
          "& .MuiButton-root": {
            m: 1,
          },
        }}
      >
        <h1>CREATE A POST</h1>
        <form onSubmit={handlePostSubmit} noValidate autoComplete="off">
          <div className="formContainer">
            <div className="thumbnailPreview">
              {thumbnailUrl ? (
                <img src={thumbnailUrl} alt="Video Thumbnail" />
              ) : (
                <h2>No preview available...</h2>
              )}
              <Button
                variant="contained"
                component="label"
                required
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "'Changa', sans-serif",
                }}
                sx={{
                  color: "white",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#343434",
                  },
                }}
              >
                Upload Video *
                <input
                  type="file"
                  hidden
                  accept="video/*"
                  onChange={(e) => setVideo(e.target.files[0])}
                />
              </Button>
            </div>
            <div className="postDetails">
              <div className="uploadFileGrid">
                <TextField
                  fullWidth
                  label="Caption"
                  variant="outlined"
                  value={caption}
                  required
                  onChange={(e) => setCaption(e.target.value)}
                  sx={{
                    maxWidth: "calc(100% - 15px)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "#343434",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "&:hover .MuiInputLabel-root": {
                      color: "#343434",
                    },
                  }}
                />
              </div>

              <FormControl
                fullWidth
                required
                style={{
                  fontFamily: "'Changa', sans-serif",
                }}
                sx={{
                  left: "8px",
                  fontFamily: "'Changa', sans-serif",
                  maxWidth: "calc(100% - 15px)",
                  marginBottom: "10px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "#343434",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "&:hover .MuiInputLabel-root": {
                    color: "#343434",
                  },
                }}
              >
                <InputLabel id="sport-select-label">Sport</InputLabel>
                <Select
                  labelId="sport-select-label"
                  id="sport-select"
                  value={sport}
                  label="Sport"
                  onChange={(e) => setSport(e.target.value)}
                >
                  {sportsSorted.map((sport) => (
                    <MenuItem key={sport} value={sport}>
                      {sport}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                required
                style={{
                  fontFamily: "'Changa', sans-serif",
                }}
                sx={{
                  left: "8px",
                  fontFamily: "'Changa', sans-serif",
                  maxWidth: "calc(100% - 15px)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "#343434",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "&:hover .MuiInputLabel-root": {
                    color: "#343434",
                  },
                }}
              >
                <InputLabel id="difficulty-select-label">Difficulty</InputLabel>
                <Select
                  labelId="difficulty-select-label"
                  id="difficulty-select"
                  value={difficulty}
                  label="Difficulty"
                  onChange={(e) => setDifficulty(e.target.value)}
                >
                  {[1, 2, 3, 4, 5].map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Location"
                variant="outlined"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                sx={{
                  maxWidth: "calc(100% - 15px)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "#343434",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "&:hover .MuiInputLabel-root": {
                    color: "#343434",
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "'Changa', sans-serif",
                  display: "flex",
                  justifyContent: "center",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                sx={{
                  color: "white",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#343434",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "transparent",
                    color: "#343434",
                  },
                }}
                disabled={!video || !caption || !sport || !difficulty}
              >
                Create Post
              </Button>
            </div>
            <Dialog open={isSubmitting} aria-labelledby="loading-dialog-title">
              <CircularProgress />
            </Dialog>
          </div>
        </form>
      </Box>
    </ThemeProvider>
  );
}

export default CreatePost;
