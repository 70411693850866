import React, { useEffect, useState } from "react";
import "../../CSS/LandingPage.css";
import TopNav from "../Components/LandingPageTopNav";
import FeatureSection from "../Components/LandingPageFeatureSection";
import videoSharingImage from "../../assets/images/featuresExplainedVideoSharing.png";
import locationSharingImage from "../../assets/images/featuresExplainedLocationSharing.png";
import sponsorshipsImage from "../../assets/images/sponsorshipsImage.png";
import communityImage from "../../assets/images/communityImage.png";
import SportsCarousel from "../Components/ScrollingSports";
import AboutSection from "../Components/AboutSection";
import FeatureCarousel from "../Components/FeatureCarousel";
import ExtremeSports from "../Components/ExtremeSports";
import Footer from "../Components/Footer";

function LandingPage() {
  const xtreme = "XTREME".split("").map((letter, index) => (
    <span key={index} className="letter">
      {letter}
    </span>
  ));

  return (
    <div className="landingPage">
      <div id="sectionOne">
        <TopNav />
        <div className="landingPageMainTitle">
          <h1>ADVENTURE</h1>
          <h1 className="xtreme">{xtreme}</h1>
          <div className="titleUnderline"></div>
          <div className="slogan">
            <div className="sloganOne">
              <p>
                <span>Send</span> It.
              </p>
            </div>
            <div className="sloganTwo">
              <p>
                <span>Share</span> It.
              </p>
            </div>
            <div className="sloganThree">
              <p>
                <span>Own</span> It.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="sectionTwo">
        <div className="aboutPageSection">
          <div className="aboutPageSectionContainer">
            <AboutSection />
          </div>
        </div>
      </div>
      <div className="sectionSeparator"></div>
      <div id="sectionThree">
        <div className="featureSection">
          <div className="featureSectionContainer">
            <h1>Features</h1>
            <FeatureCarousel />
          </div>
        </div>
      </div>
      <div className="sectionSeparator"></div>
      <div id="sectionFour">
        <div className="featuresExplainedSection">
          <div className="featuresExplainedContainer">
            <div className="featureGrid">
              <div className="featuresExplainedText">
                <h1>Video Sharing</h1>
                <p>
                  Unleash your wild side and showcase your most jaw-dropping
                  stunts with Adventure Xtreme's Video Sharing feature. Dive
                  into a world where your adrenaline-fueled moments take center
                  stage. Connect with a community that celebrates your daring
                  spirit, and get inspired by athletes from around the globe.
                  Whether it's a cliff dive, a mountain bike descent, or a
                  skateboarding trick, your adventure deserves an audience.
                  Learn more about how you can share your story, connect with
                  fans, and even get noticed by sponsors.
                </p>
              </div>
              <div className="featuresExplainedImage">
                <img src={videoSharingImage} alt="" />
                <div className="gradientOverlay"></div>
              </div>
            </div>
            <div
              className="featureGrid"
              // style={{
              //   gridTemplateColumns: "1.25fr 0.75fr",
              // }}
            >
              <div className="featuresExplainedImage">
                <img src={locationSharingImage} alt="" />
                <div className="gradientOverlay"></div>
              </div>
              <div className="featuresExplainedText">
                <h1>Location Sharing</h1>
                <p>
                  Discover hidden gems and share your secret spots with
                  Adventure Xtreme's Location Sharing. Our platform offers a
                  secure space for exchanging information about the best
                  locations for extreme sports, from untouched surf beaches to
                  uncharted mountain trails. Join a trusted network of
                  adventurers, where sharing is about preserving the thrill for
                  those who truly appreciate it. Learn more about our
                  privacy-focused approach to location sharing and how you can
                  contribute to and benefit from our collective exploration.
                </p>
              </div>
            </div>
            <div className="featureGrid">
              <div className="featuresExplainedText">
                <h1>Sponsorships</h1>
                <p>
                  Turn your passion into opportunities with Adventure Xtreme's
                  Sponsorship feature. We bridge the gap between extreme
                  athletes and brands looking for raw talent and authentic
                  representation. Whether you're an emerging talent or an
                  experienced thrill-seeker, our platform provides you with the
                  visibility to attract sponsorships that can support your
                  journey. Learn more about how you can showcase your skills,
                  connect with potential sponsors, and embark on new adventures
                  backed by brands that share your passion for the extreme.
                </p>
              </div>
              <div className="featuresExplainedImage">
                <img src={sponsorshipsImage} alt="" />
                <div className="gradientOverlay"></div>
              </div>
            </div>
            <div
              className="featureGrid"
              // style={{
              //   gridTemplateColumns: "1.25fr 0.75fr",
              //   paddingBottom: "1vh",
              // }}
            >
              <div className="featuresExplainedImage">
                <img src={communityImage} alt="" />
                <div className="gradientOverlay"></div>
              </div>
              <div className="featuresExplainedText">
                <h1>Community</h1>
                <p>
                  Join a community where extreme is the norm, and every member
                  shares your thirst for adventure. Adventure Xtreme's Community
                  feature is more than a platform; it's a global network of
                  like-minded individuals who live to push the boundaries.
                  Engage in discussions, participate in challenges, and share
                  experiences that only this community can truly understand.
                  Learn more about how you can be part of our vibrant ecosystem,
                  where support, inspiration, and camaraderie fuel our
                  collective pursuit of the extreme.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sectionSeparator"></div>
      <div id="sectionFive">
        <div className="extremeSportSection">
          <div className="extremeSportContainer">
            <h1>Extreme Sports</h1>
            <div className="mainSportsSection">
              <ExtremeSports />
            </div>
          </div>
        </div>
      </div>
      <div className="sectionSeparator"></div>
      <div id="sectionSix">
        <div className="footerSection">
          <div className="footerSectionContainer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
