import React from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

const SocialMediaForm = ({
  socialLinks,
  setSocialLinks,
  validateField,
  updateErrors,
}) => {
  // Local state for errors
  const [error, setError] = React.useState({
    instagram: false,
    tiktok: false,
    youtube: false,
  });

  // This effect updates the parent component whenever errors change
  React.useEffect(() => {
    updateErrors(error); // Notify parent component about the error state
  }, [error, updateErrors]);

  const handleValidate = (name, value) => {
    const isValid = validateField(name, value);
    setError({ ...error, [name]: !isValid });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSocialLinks({ ...socialLinks, [name]: value });
    handleValidate(name, value); // Validate on change
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    handleValidate(name, value); // Re-use validation logic
  };

  const iconStyle = { color: "white", marginRight: "5px" };

  return (
    <div style={{ padding: 20 }}>
      <Typography
        variant="h6"
        gutterBottom
        style={{ color: "white", fontFamily: "'Changa', sans-serif" }}
      >
        Enter your social media links
      </Typography>
      <Grid container spacing={2}>
        {/* Instagram Field */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Instagram"
            name="instagram"
            variant="outlined"
            value={socialLinks.instagram}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error.instagram}
            helperText={error.instagram && "Invalid Instagram link"}
            InputProps={{
              startAdornment: <FaInstagram style={iconStyle} />,
              style: { color: "white" }, // Style for the input text
            }}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
              },
            }}
          />
        </Grid>
        {/* TikTok Field */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="TikTok"
            name="tiktok"
            variant="outlined"
            value={socialLinks.tiktok}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error.tiktok}
            helperText={error.tiktok && "Invalid TikTok link"}
            InputProps={{
              startAdornment: <FaTiktok style={iconStyle} />,
              style: { color: "white" },
            }}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
              },
            }}
          />
        </Grid>
        {/* YouTube Field */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="YouTube"
            name="youtube"
            variant="outlined"
            value={socialLinks.youtube}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error.youtube}
            helperText={error.youtube && "Invalid YouTube link"}
            InputProps={{
              startAdornment: <FaYoutube style={iconStyle} />,
              style: { color: "white" },
            }}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SocialMediaForm;
