import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AiOutlineLike } from "react-icons/ai";

import "../../CSS/UserProfilePostFullView.css"; // Ensure this path is correct

const UserProfilePostFullView = ({ post, open, onClose }) => {
  const [videoOrientation, setVideoOrientation] = useState("landscape");
  const [commentText, setCommentText] = useState(""); // State to hold the input text

  const [allComments, setAllComments] = useState(
    post.comments ? post.comments : []
  );
  const [isLiked, setIsLiked] = useState(false); // State to track if the post is liked by the user
  const [likeCount, setLikeCount] = useState(post.likes.length);

  useEffect(() => {
    const likeStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/likeStatus`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "AdventureXtremeUserToken"
              )}`,
            },
            body: JSON.stringify({ postId: post._id }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          setIsLiked(result.isLiked);
          setLikeCount(result.likesCount);
          setAllComments(result.allComments);
        } else {
          const errorResult = await response.json();
          throw new Error(
            errorResult.error || "Failed to retrieve like status on the post"
          );
        }
      } catch (error) {
        console.error("Error retrieving like status on the post:", error);
      }
    };

    likeStatus();
  }, [post._id]);
  if (!post) return null;

  const handleVideoLoaded = (e) => {
    const video = e.target;
    if (video.videoWidth > video.videoHeight) {
      setVideoOrientation("landscape");
    } else {
      setVideoOrientation("portrait");
    }
  };

  const handlePostComment = async () => {
    console.log("Posting comment: ", commentText);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/addComment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ postId: post._id, text: commentText }), // Ensure you have the `post` object with `_id`
        }
      );

      if (response.ok) {
        const result = await response.json();
        const allComments = result.allComments;
        setAllComments(allComments);
        setCommentText(""); // Clear the input field
      } else {
        throw new Error("Failed to post comment");
      }
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  const handleLike = async () => {
    try {
      const endpoint = isLiked ? "unlikePost" : "likePost";
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ postId: post._id }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result.likes);
        setLikeCount(result.likes);
        setIsLiked(!isLiked);
      } else {
        const errorResult = await response.json();
        throw new Error(
          errorResult.error || "Failed to toggle like on the post"
        );
      }
    } catch (error) {
      console.error("Error toggling like on the post:", error);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "90vw",
          width: "90vw",
          maxHeight: "90vh",
          height: "90vh",
          m: "auto",
          backgroundColor: "#000b0c",
          border: "3px solid #343434",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          className={
            videoOrientation === "portrait" ? "videoPortrait" : "videoLandscape"
          }
        >
          <div
            className={
              videoOrientation === "portrait"
                ? "videoPortraitVideoContainer"
                : "videoLandscapeVideoContainer"
            }
          >
            <div className="videoWrapper">
              {post.videoUrl && (
                <video
                  controls
                  className={
                    videoOrientation === "portrait"
                      ? "videoPortraitPostVideo"
                      : "videoLandscapePostVideo"
                  }
                  src={post.videoUrl}
                  type="video/mp4"
                  onLoadedMetadata={handleVideoLoaded}
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
            <div
              className={
                videoOrientation === "portrait"
                  ? "videoPortraitInsights"
                  : "videoLandscapeInsights"
              }
            >
              <div
                className={
                  videoOrientation === "portrait"
                    ? "videoPortraitLikes"
                    : "videoLandscapeLikes"
                }
              >
                {likeCount} Likes
              </div>
              <AiOutlineLike
                style={{
                  cursor: "pointer",
                  color: isLiked ? "blue" : "white",
                }}
                onClick={handleLike}
                className={
                  videoOrientation === "portrait"
                    ? "videoPortraitLikeButton"
                    : "videoLandscapeLikeButton"
                }
              />
              <div
                className={
                  videoOrientation === "portrait"
                    ? "videoPortraitCommentCount"
                    : "videoLandscapeCommentCount"
                }
              >
                {allComments.length} Comments
              </div>
            </div>
            <div
              className={
                videoOrientation === "portrait"
                  ? "videoPortraitUserVideoCaption"
                  : "videoLandscapeUserVideoCaption"
              }
            >
              <strong>{post.username}:</strong>
              <br></br>
              {post.caption}
            </div>
          </div>

          <div
            className={
              videoOrientation === "portrait"
                ? "videoPortraitComments"
                : "videoLandscapeComments"
            }
          >
            <h1>Comments</h1>
            <div className="lineSeparation"></div>
            <div className="commentsSection">
              <div className="commentsList">
                {allComments.map((comment, index) => (
                  <div key={index} className="comment">
                    {comment.username}: {comment.text}
                  </div>
                ))}
              </div>
              <div className="lineSeparation"></div>
              <div className="postComment">
                <TextField
                  label="Write a comment..."
                  variant="outlined"
                  fullWidth
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "#343434",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "&:hover .MuiInputLabel-root": {
                      color: "#343434",
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePostComment}
                  style={{ backgroundColor: "transparent" }}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#343434",
                    },
                  }}
                >
                  Post
                </Button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UserProfilePostFullView;
