import React, { useState, useEffect } from "react";

function VideoThumbnail({ videoUrl }) {
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  useEffect(() => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Function to capture and set thumbnail
    const captureThumbnail = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setThumbnailUrl(canvas.toDataURL("image/jpeg"));
      video.removeEventListener("seeked", captureThumbnail);
    };

    video.preload = "metadata";
    video.src = videoUrl;

    // When metadata is loaded, set video currentTime to half of its duration
    video.addEventListener("loadedmetadata", () => {
      video.currentTime = video.duration / 2;
    });

    // Capture thumbnail once video has seeked to the new currentTime
    video.addEventListener("seeked", captureThumbnail);

    return () => {
      video.removeEventListener("loadedmetadata", () => {});
      video.removeEventListener("seeked", captureThumbnail);
    };
  }, [videoUrl]);

  return (
    <div style={{ width: "100%", maxWidth: "500px" }}>
      {thumbnailUrl && (
        <img
          src={thumbnailUrl}
          alt="Video Thumbnail"
          style={{ width: "100%", height: "auto" }} // This ensures the image scales with the container width
        />
      )}
    </div>
  );
}

export default VideoThumbnail;
