import React, { useState } from "react";
import "../../CSS/LoginRegister.css";
import ToggleSwitch from "../Components/ToggleSwitch";
import SignUpForm from "../Components/SignUpForm";
import { auth, googleProvider } from "./firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import googleButtonImage from "../../assets/images/web_light_rd_ctn@4x.png";

function LoginRegister() {
  const [isSignUp, setIsSignUp] = useState(true);

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // This gives you a Google Access Token. You can use it to access Google APIs.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log(JSON.stringify(user, null, 2));
      console.log(
        "Your email is: " + user.email + "\nYour name is: " + user.displayName
      );
    } catch (error) {
      console.error(error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    }
  };
  return (
    <div className="SignUpLandingPage">
      <div className="headerContainer">
        <h1>Welcome to Adventure Xtreme!</h1>
        <h6>Login or Sign Up below to gain full access to our platform!</h6>
      </div>
      <div className="toggleMethods">
        <div className="toggleButton">
          <ToggleSwitch onToggle={toggleForm} />
          {isSignUp ? (
            <div className="selectedMethod">
              <h1>Sign Up</h1>
              <div className="signUpFormContainer"></div>
              <SignUpForm />
            </div>
          ) : (
            <div className="selectedMethod">
              <h1>Sign In</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginRegister;
