import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

const whiteTheme = createTheme({
  typography: {
    fontFamily: "'Changa', sans-serif",
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#fff",
          "&:focus": {
            backgroundColor: "#000b0c",
          },
        },
        icon: {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000b0c",
          ".MuiMenu-list": {
            border: "2px solid",
            borderColor: "#343434",
            borderRadius: "10px",
            fontFamily: "'Changa', sans-serif",
          },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#000b0c",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#343434",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#ffffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#343434",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#343434",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
        },
      },
    },
    // Adding styles for MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #343434",
          borderRadius: "15px",
        },
      },
    },
  },
});
const UniversalNotifications = ({ open, message, handleClose }) => {
  return (
    <ThemeProvider theme={whiteTheme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Notification"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default UniversalNotifications;

// Sample use
// import React, { useState } from 'react';
// import UniversalNotifications from './UniversalNotifications';

// const ParentComponent = () => {
//   const [openNotification, setOpenNotification] = useState(false);
//   const [notificationMessage, setNotificationMessage] = useState('');

//   const handleOpenNotification = () => {
//     setNotificationMessage('This is your notification message!');
//     setOpenNotification(true);
//   };

//   const handleCloseNotification = () => {
//     setOpenNotification(false);
//   };

//   return (
//     <div>
//       <button onClick={handleOpenNotification}>Show Notification</button>
//       <UniversalNotifications
//         open={openNotification}
//         message={notificationMessage}
//         handleClose={handleCloseNotification}
//       />
//     </div>
//   );
// };

// export default ParentComponent;
