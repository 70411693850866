import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AiOutlineLike } from "react-icons/ai";

// Ensure CSS path is correct for your project structure
import "../../CSS/DiscoverFeedPosts.css";

const DiscoverFeedPosts = ({ post, onNavigateToProfile }) => {
  const [videoOrientation, setVideoOrientation] = useState("landscape");
  const [commentText, setCommentText] = useState("");
  const [allComments, setAllComments] = useState(
    post.comments ? post.comments : []
  );
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(post.likes.length);

  useEffect(() => {
    const likeStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/likeStatus`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "AdventureXtremeUserToken"
              )}`,
            },
            body: JSON.stringify({ postId: post._id }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          setIsLiked(result.isLiked);
          setLikeCount(result.likesCount);
          setAllComments(result.allComments);
        } else {
          const errorResult = await response.json();
          throw new Error(
            errorResult.error || "Failed to retrieve like status on the post"
          );
        }
      } catch (error) {
        console.error("Error retrieving like status on the post:", error);
      }
    };

    likeStatus();
  }, [post._id]);
  if (!post) return null;

  const handleUsernameClick = () => {
    if (onNavigateToProfile) onNavigateToProfile("Profile", post.username);
  };

  const handleVideoLoaded = (e) => {
    const video = e.target;
    if (video.videoWidth > video.videoHeight) {
      setVideoOrientation("landscape");
    } else {
      setVideoOrientation("portrait");
    }
  };

  const handlePostComment = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/addComment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ postId: post._id, text: commentText }), // Ensure you have the `post` object with `_id`
        }
      );

      if (response.ok) {
        const result = await response.json();
        const allComments = result.allComments;
        setAllComments(allComments);
        setCommentText(""); // Clear the input field
      } else {
        throw new Error("Failed to post comment");
      }
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  const handleLike = async () => {
    try {
      const endpoint = isLiked ? "unlikePost" : "likePost";
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ postId: post._id }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setLikeCount(result.likes);
        setIsLiked(!isLiked);
      } else {
        const errorResult = await response.json();
        throw new Error(
          errorResult.error || "Failed to toggle like on the post"
        );
      }
    } catch (error) {
      console.error("Error toggling like on the post:", error);
    }
  };
  // Render component
  return (
    <div>
      <div className="userNameTopBarPost">
        <h2 onClick={handleUsernameClick}>{post.username}:</h2>
      </div>
      <div
        className={
          videoOrientation === "portrait"
            ? "postVideoPortraitDiscover"
            : "postVideoLandscapeDiscover"
        }
      >
        <div
          className={
            videoOrientation === "portrait"
              ? "postVideoPortraitVideoContainerDiscover"
              : "postVideoLandscapeVideoContainerDiscover"
          }
        >
          <div
            className={
              videoOrientation === "portrait"
                ? "videoWrapperPortraitDiscover"
                : "videoWrapperLandscapeDiscover"
            }
          >
            {post.videoUrl && (
              <video
                controls
                className={
                  videoOrientation === "portrait"
                    ? "postVideoPortraitPostVideoDiscover"
                    : "postVideoLandscapePostVideoDiscover"
                }
                src={post.videoUrl}
                type="video/mp4"
                onLoadedMetadata={handleVideoLoaded}
              >
                Your browser does not support the video tag.
              </video>
            )}
          </div>
          <div
            className={
              videoOrientation === "portrait"
                ? "postVideoPortraitInsightsDiscover"
                : "postVideoLandscapeInsightsDiscover"
            }
          >
            <div
              className={
                videoOrientation === "portrait"
                  ? "postVideoPortraitLikesDiscover"
                  : "postVideoLandscapeLikesDiscover"
              }
            >
              {likeCount} Likes
            </div>
            <AiOutlineLike
              style={{ cursor: "pointer", color: isLiked ? "blue" : "white" }}
              onClick={handleLike}
              className={
                videoOrientation === "portrait"
                  ? "postVideoPortraitLikeButtonDiscover"
                  : "postVideoLandscapeLikeButtonDiscover"
              }
            />
            <div
              className={
                videoOrientation === "portrait"
                  ? "postVideoPortraitCommentCountDiscover"
                  : "postVideoLandscapeCommentCountDiscover"
              }
            >
              {allComments.length} Comments
            </div>
          </div>
          <div
            className={
              videoOrientation === "portrait"
                ? "postVideoPortraitUserVideoCaptionDiscover"
                : "postVideoLandscapeUserVideoCaptionDiscover"
            }
          >
            <strong>{post.username}:</strong>
            <br></br>
            {post.caption}
          </div>
        </div>

        <div
          className={
            videoOrientation === "portrait"
              ? "postVideoPortraitCommentsDiscover"
              : "postVideoLandscapeCommentsDiscover"
          }
        >
          <h1>Comments</h1>
          {/* <div className="lineSeparation"></div> */}
          <div className="commentsSectionDiscover">
            <div className="commentsListDiscover">
              {allComments.map((comment, index) => (
                <div key={index} className="commentDiscover">
                  <strong>{comment.username}: </strong>
                  {comment.text}
                </div>
              ))}
            </div>
          </div>
          {/* <div className="lineSeparation"></div> */}
          <div className="postCommentDiscover">
            <TextField
              label="Write a comment..."
              variant="outlined"
              fullWidth
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "#343434",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "&:hover .MuiInputLabel-root": {
                  color: "#343434",
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handlePostComment}
              style={{ backgroundColor: "transparent" }}
              sx={{
                color: "white",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#343434",
                },
              }}
            >
              Post
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverFeedPosts;
