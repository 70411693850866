import React from "react";

function ConsentFormContent() {
  return (
    <div className="informationSheetContainer" style={{ width: "100%" }}>
      <div className="informationSheetHeader" style={{ textAlign: "center" }}>
        <span>
          <h1>Consent Information Sheet</h1>
        </span>
      </div>
      <div className="informationSheetContent" style={{ textAlign: "center" }}>
        <p style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          Welcome to Adventure Xtreme, the premier online community dedicated to
          extreme sports enthusiasts. Before you dive into the wealth of content
          and community features we offer, it's important to acknowledge the
          nature of extreme sports and the content shared on this platform.
        </p>

        <p style={{ paddingBottom: "20px" }}>
          <span>
            <strong>Risk Acknowledgment:</strong>
          </span>
          <br></br> Extreme sports involve significant risks of injury,
          including serious bodily harm or death. The activities discussed and
          videos shared here should only be attempted by individuals with
          appropriate training, equipment, and under the supervision of
          professionals. Adventure Xtreme does not endorse or encourage
          attempting any dangerous activities without proper preparation and
          safety measures.
        </p>

        <p style={{ paddingBottom: "20px" }}>
          <span>
            <strong>Responsible Participation:</strong>
          </span>
          <br></br> We urge all members to practice responsible participation in
          extreme sports. This includes understanding the risks, seeking proper
          training, using the right equipment, and respecting both personal
          limitations and the environment. Sharing experiences on Adventure
          Xtreme should inspire positive action and awareness, not recklessness
          or harm.
        </p>

        <p style={{ paddingBottom: "20px" }}>
          <span>
            <strong>Content Guidelines:</strong>
          </span>
          <br></br> All content shared on Adventure Xtreme should adhere to our
          community guidelines, promoting safety, respect, and inclusivity.
          Content that encourages unsafe practices, violates laws, or
          disrespects community members will be removed.
        </p>

        <p style={{ paddingBottom: "20px" }}>
          <span>
            <strong>Privacy and Consent:</strong>
          </span>
          <br></br> Respect the privacy and rights of others when sharing
          content. Ensure you have the consent of all individuals featured in
          any content you upload.
        </p>

        <p style={{ paddingBottom: "20px" }}>
          By participating in the Adventure Xtreme community, you acknowledge
          the risks associated with extreme sports and commit to promoting a
          culture of safety and respect. Let's inspire each other through our
          passion for extreme sports, always prioritizing safety and
          responsibility.
        </p>

        <p style={{ paddingBottom: "20px" }}>
          Thank you for being a part of Adventure Xtreme. Explore safely and
          responsibly!
        </p>
      </div>
    </div>
  );
}

export default ConsentFormContent;
