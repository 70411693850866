import React from "react";
import SteppedProgress from "../Components/SteppedProgress";
import "../../CSS/Register.css";

function Register() {
  return (
    <div className="registerPage">
      <div className="loginRegisterTitle">
        <span>Adventure Xtreme</span>
      </div>
      <div className="registerContainer">
        <div className="regitserHeader">
          <span>Register</span>
        </div>
        <SteppedProgress />
      </div>
    </div>
  );
}

export default Register;
