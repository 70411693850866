import React, { useEffect, useState } from "react";
import UserProfilePostFullView from "./UserProfilePostFullView";
import { MdDelete } from "react-icons/md";
import "../../CSS/UserProfilePosts.css";

const UserProfilePosts = ({ viewedUsername, isOwnProfile, fetchUserPosts }) => {
  const [selectedPost, setSelectedPost] = useState(null);
  const [isFullViewOpen, setIsFullViewOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handlePostClick = (post) => {
    setSelectedPost(post);

    setIsFullViewOpen(true);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      setError("");
      try {
        const token = localStorage.getItem("AdventureXtremeUserToken");
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/fetchUserPosts/${viewedUsername}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch posts");
        }

        const data = await response.json();
        setPosts(data);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [viewedUsername]);

  const handleDelete = async (postId) => {
    if (!postId) {
      alert("Post ID is missing.");
      return;
    }

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (!confirmDelete) {
      return;
    }

    setIsDeleting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/deletePost`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ postId: postId }),
        }
      );

      if (response.ok) {
        alert("Post deleted successfully.");
        await fetchUserPosts();
      } else {
        const error = await response.text();
        console.error("Error deleting post:", error);
        alert("Error deleting post. Please try again.");
      }
    } catch (error) {
      console.error("Fetch error:", error.message);
      alert("An error occurred. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="posts-grid">
      {posts.map((post, index) => (
        <div key={index} className="post-item">
          <div className="postContainer">
            {isOwnProfile && (
              <MdDelete
                className="deleteIcon"
                onClick={() => handleDelete(post._id)}
              />
            )}
            {post.videoThumbnailUrl ? (
              <img
                src={post.videoThumbnailUrl}
                alt="Post Thumbnail"
                onClick={() => handlePostClick(post)}
              />
            ) : (
              <div>No preview available</div>
            )}
          </div>
        </div>
      ))}
      {selectedPost && (
        <UserProfilePostFullView
          post={selectedPost}
          open={isFullViewOpen}
          onClose={() => setIsFullViewOpen(false)}
        />
      )}
    </div>
  );
};

export default UserProfilePosts;
