import React, { useState } from "react";
import "../../CSS/DashboardHome.css";
import { sports } from "../../constants";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import DiscoverFeed from "./DiscoverFeed";
import "../../CSS/DashboardDiscover.css";
import BackArrow from "../../assets/images/backArrow.svg";
import BackArrowHover from "../../assets/images/backArrowHover.svg";

const whiteTheme = createTheme({
  typography: {
    fontFamily: "'Changa', sans-serif",
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#fff",
          "&:focus": {
            backgroundColor: "#000b0c",
          },
        },
        icon: {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000b0c",
          ".MuiMenu-list": {
            border: "2px solid",
            borderColor: "#343434",
            borderRadius: "10px",
            fontFamily: "'Changa', sans-serif",
          },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#000b0c",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#343434",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#ffffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#343434",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#343434",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
        },
      },
    },
    // Adding styles for MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #343434",
          borderRadius: "15px",
        },
      },
    },
  },
});
const DashboardDiscover = ({ onNavigateToProfile }) => {
  const [selectedSport, setSelectedSport] = useState("all");
  const [difficulty, setDifficulty] = useState("all");
  const [backArrowHover, setBackArrowHover] = useState(false);

  const handleSportChange = (event) => {
    setSelectedSport(event.target.value);
  };

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };
  const handleBackButton = () => {
    if (onNavigateToProfile) onNavigateToProfile("Home");
  };
  return (
    <div>
      <div
        className="backButton"
        onMouseEnter={() => setBackArrowHover(true)}
        onMouseLeave={() => setBackArrowHover(false)}
        onClick={handleBackButton} // Assuming you want the whole area clickable
        style={{ cursor: "pointer" }}
      >
        {" "}
        {/* Ensure the cursor changes to pointer */}
        <img src={backArrowHover ? BackArrowHover : BackArrow} alt="Back" />
      </div>
      <div className="dashboardHomePage">
        <div className="dashboardHomeContentContainer">
          <div className="homeFilteringSection">
            <ThemeProvider theme={whiteTheme}>
              <FormControl
                sx={{
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "25%",
                }}
              >
                <InputLabel>Sport</InputLabel>
                <Select
                  value={selectedSport}
                  label="Sport"
                  onChange={handleSportChange}
                >
                  {[
                    { value: "all", label: "All Sports" },
                    ...sports
                      .sort()
                      .map((sport) => ({ value: sport, label: sport })),
                  ].map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                sx={{
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "25%",
                }}
              >
                <InputLabel>Difficulty</InputLabel>
                <Select
                  value={difficulty}
                  label="Difficulty"
                  onChange={handleDifficultyChange}
                >
                  {[
                    { value: "all", label: "All Difficulties" },
                    ...[1, 2, 3, 4, 5].map((level) => ({
                      value: level.toString(),
                      label: level,
                    })),
                  ].map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ThemeProvider>
          </div>

          <DiscoverFeed
            selectedSport={selectedSport}
            difficulty={difficulty}
            onNavigateToProfile={onNavigateToProfile} // Passed through to DiscoverFeed
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardDiscover;
