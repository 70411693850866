import React, { useState } from "react";
import "../../CSS/LandingPageFeatureSection.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";

function LandingPageFeatureSection() {
  const features = [
    {
      title: "Video Sharing",
      description:
        "Share your epic videos to a community of people that understands",
    },
    {
      title: "Locations",
      description:
        "Want to share a location but don't want the natural beauty ruined? Share locations with people only you trust",
    },
    {
      title: "Sponsorships",
      description:
        "Exclusive portal for companies to see profiles of our users to give them the best chance of getting sponsored",
    },
    {
      title: "Community",
      description: "Connect with like-minded people around the world",
    },
  ];

  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);

  const goToPrevFeature = () => {
    setCurrentFeatureIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : features.length - 1
    );
  };

  const goToNextFeature = () => {
    setCurrentFeatureIndex((prevIndex) =>
      prevIndex < features.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="LandingPageFeatureSectionComponent">
      <div className="carouselGrid">
        <div className="prev" onClick={goToPrevFeature}>
          <ArrowBackIosIcon
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
          />
        </div>
        <div className="content">
          <div className="featureCard">
            <h2
              style={{
                padding: 0,
                color: "red",
                fontWeight: "300",
                fontSize: "1.6rem",
              }}
            >
              {features[currentFeatureIndex].title}
            </h2>
            <p
              style={{
                fontSize: "1.1rem",
              }}
            >
              {features[currentFeatureIndex].description}
            </p>
          </div>
        </div>
        <div className="next" onClick={goToNextFeature}>
          <ArrowForwardIosIcon
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
}

export default LandingPageFeatureSection;
