import React, { useState, useRef, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { Icon, divIcon } from "leaflet";
import locationPin from "../../assets/images/location-pin.svg";
import MarkerClusterGroup from "react-leaflet-cluster";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import { sports } from "../../constants";
import { ThemeProvider } from "@mui/material/styles";

import "leaflet/dist/leaflet.css";
import "../../CSS/MyLocations.css";
import { createTheme } from "@mui/material/styles";
import testImage from "../../assets/images/adventureXtremeLandingPage.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { MdDelete } from "react-icons/md";
import BackArrow from "../../assets/images/backArrow.svg";
import BackArrowHover from "../../assets/images/backArrowHover.svg";
import UniversalNotifications from "./UniversalNotifications";
import Expand from "../../assets/images/expand.svg";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon from MUI

const whiteTheme = createTheme({
  typography: {
    fontFamily: "'Changa', sans-serif",
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#fff",
          "&:focus": {
            backgroundColor: "#000b0c",
          },
        },
        icon: {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000b0c",
          ".MuiMenu-list": {
            border: "2px solid",
            borderColor: "#343434",
            borderRadius: "10px",
            fontFamily: "'Changa', sans-serif",
          },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#000b0c",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#343434",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#ffffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#343434",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#343434",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
        },
      },
    },
    // Adding styles for MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #343434",
          borderRadius: "15px",
        },
      },
    },
  },
});

function MapClickHandler({ onMapClick }) {
  useMapEvents({
    click(e) {
      onMapClick([e.latlng.lat, e.latlng.lng]);
    },
  });
  return null;
}

const MyLocations = ({ onNavigateToProfile }) => {
  const [markers, setMarkers] = useState([
    {
      geocode: [53.34453777212165, -6.261891353805137],
      title: "Crows Nest",
      image: testImage,
      latitude: 53.34453777212165,
      longitude: -6.261891353805137,
      description:
        "the place where it started it all. 11m/34ft. Only jump at high tide.",
      safety: "Do your own depth checks",
      sport: "Cliff Diving",
    },
    {
      geocode: [53.33674432709882, -6.287639453569416],
      title: "Howth Boards",
      image: testImage,
      latitude: 53.33674432709882,
      longitude: -6.287639453569416,
      description:
        "the place where it started it all. 11m/34ft. Only jump at high tide.",
      safety: "Do your own depth checks",
      sport: "Cliff Diving",
    },
    {
      geocode: [53.354995157269315, -6.268929167740705],
      title: "Harvey's Cliff",
      image: testImage,
      latitude: 53.354995157269315,
      longitude: -6.268929167740705,
      description:
        "the place where it started it all. 11m/34ft. Only jump at high tide.",
      safety: "Do your own depth checks",
      sport: "Cliff Diving",
    },
  ]);
  const [tempMarkerCoords, setTempMarkerCoords] = useState(null);
  const [tempMarkerPopup, setTempMarkerpopup] = useState("");
  const markerRef = useRef(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [safetyAdvice, setSafetyAdvice] = useState("");
  const [selectedSport, setSelectedSport] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const [currentView, setCurrentView] = useState("add");
  const [validationMessage, setValidationMessage] = useState("");
  const [markersDB, setMarkersDB] = useState([]);
  const [geocode, setGeocode] = useState(null);
  const [recipientUsername, setRecipientUsername] = useState("");
  const [backArrowHover, setBackArrowHover] = useState(false);
  const [universalErrorMessage, setUniversalErrorMessage] = useState(false);
  const [
    universalErrorMessageNotification,
    setUniversalErrorMessageNotification,
  ] = useState("");
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const customIcon = new Icon({
    iconUrl: locationPin,
    iconSize: [38, 38],
  });

  const handleCloseUniversalErrorMessage = () => {
    setUniversalErrorMessage(false);
  };

  const createCustomClusterIcon = (cluster) => {
    return new divIcon({
      html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster",
      iconSize: [33, 33],
    });
  };

  const handleAddMarker = (geocode) => {
    setGeocode(geocode);
    setLatitude(geocode[0]);
    setLongitude(geocode[1]);
    setTempMarkerCoords([geocode[0], geocode[1]]);
    setTempMarkerpopup(
      <div>
        <span>
          <span style={{ fontWeight: "bold" }}>Latitude: </span>
          {geocode[0]}
        </span>
        <span>
          <br />
          <span style={{ fontWeight: "bold" }}>Longitude: </span>
          {geocode[1]}
        </span>
      </div>
    );
    setTimeout(() => {
      if (markerRef.current) {
        markerRef.current.openPopup();
      }
    }, 10);
  };

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [tempMarkerCoords]);

  const handleNewLocationSubmit = async (e) => {
    e.preventDefault();
    if (!title) {
      setValidationMessage("Please fill out the Location Title field.");
      return;
    } else if (!latitude) {
      setValidationMessage("Please fill out the Latitude field.");
      return;
    } else if (!longitude) {
      setValidationMessage("Please fill out the Longitude field.");
      return;
    } else if (!selectedSport) {
      setValidationMessage("Please fill out the Sport field.");
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("geocode", JSON.stringify(geocode));
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("description", description);
    formData.append("safety", safetyAdvice);
    formData.append("sport", selectedSport);
    if (image) {
      formData.append("image", image);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/newLocation`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        setMarkersDB(result.locations);
      } else {
        const errorResult = await response.json();
        throw new Error(errorResult.error || "Failed to create new location");
      }
    } catch (error) {
      console.error("Error creating new location:", error);
      setUniversalErrorMessageNotification(error);
      setUniversalErrorMessage(true);
    }
    setValidationMessage("");
    setTitle("");
    setGeocode("");
    setDescription("");
    setSafetyAdvice("");
    setSelectedSport("");
    setImage(null);
    setImageUrl("");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      setImage(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  const handleOpenDialog = (index) => {
    setOpenDialogIndex(index);
  };

  const handleCloseDialog = () => {
    setOpenDialogIndex(null);
  };

  const selectView = (view) => {
    setCurrentView(view);
  };

  const fetchUserLocations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/fetchUserLocations`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMarkersDB(data);
      } else {
        throw new Error("Failed to fetch locations");
      }
    } catch (error) {
      console.error("Error fetching locations:", error.message);
      setUniversalErrorMessageNotification(error.message);
      setUniversalErrorMessage(true);
    }
  };
  useEffect(() => {
    fetchUserLocations();
  }, []);
  const deleteLocation = async (locationId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/deleteLocation`,
        {
          method: "POST", // Assuming deletion is handled via POST request
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({ locationId }),
        }
      );

      if (response.ok) {
        // Handle successful deletion
        // For example, remove the location from the local state to update the UI
        setMarkersDB((prevMarkers) =>
          prevMarkers.filter((marker) => marker._id !== locationId)
        );
        //alert("Location deleted successfully.");"Please enter a recipient's username."
        setUniversalErrorMessageNotification("Location deleted successfully.");
        setUniversalErrorMessage(true);
      } else {
        throw new Error("Failed to delete the location.");
      }
    } catch (error) {
      console.error("Error deleting location:", error.message);
      setUniversalErrorMessageNotification(
        "There was an error deleting your location" + error
      );
      setUniversalErrorMessage(true);
      //alert("Error deleting location: " + error.message);
    }
  };
  const sendShareRequest = async (locationId) => {
    if (!recipientUsername.trim()) {
      //alert("Please enter a recipient's username."); // Or handle validation more formally
      setUniversalErrorMessageNotification(
        "Please enter a recipient's username."
      );
      setUniversalErrorMessage(true);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/shareLocation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`,
          },
          body: JSON.stringify({
            locationId,
            recipientUsername,
          }),
        }
      );

      if (response.ok) {
        //alert("Location shared successfully!"); // Or handle success more formally
        setUniversalErrorMessageNotification("Location shared successfully!");
        setUniversalErrorMessage(true);
      } else {
        const errorResult = await response.json();
        throw new Error(errorResult.error || "Failed to share location");
      }
    } catch (error) {
      console.error("Error sharing location:", error);
      setUniversalErrorMessageNotification("Error sharing location:" + error);
      setUniversalErrorMessage(true);
      //alert(error.message); // Or handle error more formally
    }

    // Reset dialog state and close it
    setRecipientUsername("");
    handleCloseDialog();
  };
  const handleBackButton = () => {
    if (onNavigateToProfile) onNavigateToProfile("Home");
  };

  const handleClickOpen = (imageSrc) => {
    setCurrentImage(imageSrc); // Update the state with the new image source
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div
        className="backButton"
        onMouseEnter={() => setBackArrowHover(true)}
        onMouseLeave={() => setBackArrowHover(false)}
        onClick={handleBackButton} // Assuming you want the whole area clickable
        style={{ cursor: "pointer" }}
      >
        {" "}
        {/* Ensure the cursor changes to pointer */}
        <img src={backArrowHover ? BackArrowHover : BackArrow} alt="Back" />
      </div>
      <div className="myLocationsPage">
        <div className="locationsPageDivider">
          <MapContainer
            center={[53.3498, -6.2603]}
            zoom={13}
            scrollWheelZoom={true}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapClickHandler onMapClick={handleAddMarker} />
            <MarkerClusterGroup
              chunkedLoading
              iconCreateFunction={createCustomClusterIcon}
            >
              {markersDB.map((marker, index) => (
                <Marker key={index} position={marker.geocode} icon={customIcon}>
                  <Popup className="popUpContainer">
                    <div className="popUpGrid">
                      <div
                        className="popUpGridImageSection"
                        onClick={() => handleClickOpen(marker.image)}
                      >
                        {marker.image ? (
                          <img
                            src={marker.image}
                            style={{
                              position: "relative",
                              maxWidth: "90%",
                              width: "90%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              borderRadius: "10px",
                            }}
                          />
                        ) : (
                          <h2
                            style={{
                              textAlign: "center",
                              fontFamily: "'Changa', sans-serif",
                            }}
                          >
                            No image available
                          </h2>
                        )}
                        <img
                          src={Expand}
                          alt="Expand"
                          className="expand-icon"
                          style={{
                            maxWidth: "3rem",
                            maxHeight: "3rem",
                            padding: "0px",
                            margin: "0px",
                          }}
                        />
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="image-dialog-title"
                          PaperProps={{
                            style: {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                              overflow: "hidden",
                            },
                          }}
                        >
                          <img
                            src={currentImage}
                            alt="Marker"
                            className="full-image"
                            style={{
                              position: "relative",
                              maxWidth: "100%",
                              height: "auto",
                            }}
                          />
                          <CloseIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClose();
                            }}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              color: "#343434",
                              cursor: "pointer",
                              fontSize: "2rem",
                            }}
                          />
                        </Dialog>
                      </div>
                      <div className="popUpGridDetailsSection">
                        <h1
                          style={{
                            fontFamily: "'Changa', sans-serif",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          {marker.title}
                        </h1>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Creator: </strong>
                          {marker.creatorUsername}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Latitude: </strong>
                          {marker.latitude}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Longitude: </strong>
                          {marker.longitude}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Description: </strong>
                          {marker.description}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Safety Advice: </strong>
                          {marker.safety}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Sport: </strong>
                          {marker.sport}
                        </div>
                      </div>
                      <div className="popUpButtonContainer">
                        <Button
                          style={{
                            position: "relative",
                            left: "50%",
                            transform: "translateX(-50%)",
                            fontWeight: "bold",
                            padding: "0px",
                            margin: "0px",
                          }}
                          onClick={() => handleOpenDialog(index)}
                        >
                          SHARE
                        </Button>
                        <Button
                          style={{
                            position: "relative",
                            left: "50%",
                            transform: "translateX(-50%)",
                            fontWeight: "bold",
                          }}
                          onClick={() => deleteLocation(marker._id)}
                        >
                          DELETE
                        </Button>
                      </div>
                    </div>
                  </Popup>
                  <ThemeProvider theme={whiteTheme}>
                    <Dialog
                      open={openDialogIndex === index}
                      onClose={handleCloseDialog}
                    >
                      <DialogTitle>Share {marker.title}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Enter the username of the person you want to share
                          this place with:
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Recipient's Username"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={recipientUsername}
                          onChange={(e) => setRecipientUsername(e.target.value)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button
                          onClick={() => {
                            handleCloseDialog();
                            sendShareRequest(markersDB[openDialogIndex]._id);
                          }}
                        >
                          Send
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </ThemeProvider>
                </Marker>
              ))}
              {tempMarkerCoords && (
                <Marker
                  position={tempMarkerCoords}
                  icon={customIcon}
                  ref={markerRef}
                >
                  <Popup>{tempMarkerPopup}</Popup>
                </Marker>
              )}
            </MarkerClusterGroup>
          </MapContainer>
          <div className="areaSeparator">
            <div className="lineSeparator"></div>
            <div className="locationOptionChooser">
              <div
                className={`locationOptionOne ${
                  currentView === "view" ? "selected" : ""
                }`}
                onClick={() => selectView("view")}
              >
                <h1>VIEW MY SPOTS</h1>
              </div>
              <div
                className={`locationOptionTwo ${
                  currentView === "add" ? "selected" : ""
                }`}
                onClick={() => selectView("add")}
              >
                <h1>ADD NEW SPOT</h1>
              </div>
            </div>
            <div className="lineSeparator"></div>
          </div>
          <div>
            {currentView === "add" && (
              <ThemeProvider theme={whiteTheme}>
                <form className="formStyles">
                  <div className="locationAddImage">
                    {imageUrl && <img src={imageUrl} alt="Uploaded" />}
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        id="fileInput"
                      />
                      <label
                        htmlFor="fileInput" // This associates the label with the input
                        style={{
                          cursor: "pointer", // Optional: Changes the cursor to indicate clickable
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents the label's default action if needed
                          }}
                        >
                          Choose Image
                        </Button>
                      </label>
                    </div>
                  </div>
                  <div className="locationAddDetails">
                    <TextField
                      label="Location Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      fullWidth
                      required
                    />
                    <TextField
                      label="Latitude"
                      value={latitude || ""}
                      onChange={(e) => setLatitude(e.target.value)}
                      fullWidth
                      required
                    />
                    <TextField
                      label="Longitude"
                      value={longitude || ""}
                      onChange={(e) => setLongitude(e.target.value)}
                      fullWidth
                      required
                    />
                    <TextField
                      label="Description"
                      value={description}
                      multiline
                      rows={4}
                      onChange={(e) => setDescription(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label="Safety Advice"
                      value={safetyAdvice}
                      onChange={(e) => setSafetyAdvice(e.target.value)}
                      fullWidth
                    />
                    <FormControl fullWidth>
                      <InputLabel>Sport</InputLabel>
                      <Select
                        value={selectedSport}
                        label="Sport"
                        onChange={(e) => setSelectedSport(e.target.value)}
                        required
                      >
                        {sports.sort().map((sport, index) => (
                          <MenuItem key={index} value={sport}>
                            {sport}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </form>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNewLocationSubmit}
                  style={{
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-50%)",
                    padding: "10px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Add Location
                </Button>
                {validationMessage && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    {validationMessage}
                  </p>
                )}
              </ThemeProvider>
            )}
            {currentView === "view" && (
              <div className="viewAllMyLocationsSection">
                <div className="allLocationsGridContainer">
                  {markersDB.map((marker, index) => (
                    <div className="allLocationsSeparateGrid" key={index}>
                      {/* <div className="sidebySideinfo"> */}
                      <div
                        className="allLocationsDetailsGrid"
                        style={{ padding: "15px" }}
                      >
                        <h1
                          style={{
                            fontFamily: "'Changa', sans-serif",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          {marker.title}
                        </h1>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Creator: </strong>
                          {marker.creatorUsername}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Latitude: </strong>
                          {marker.latitude}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Longitude: </strong>
                          {marker.longitude}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Description: </strong>
                          {marker.description}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Safety Advice: </strong>
                          {marker.safety}
                        </div>
                        <div style={{ fontFamily: "'Changa', sans-serif" }}>
                          <strong>Sport: </strong>
                          {marker.sport}
                        </div>
                      </div>
                      <div
                        className="allLocationsGridImage"
                        onClick={() => handleClickOpen(marker.image)}
                      >
                        {marker.image ? (
                          <img
                            src={marker.image}
                            style={{
                              position: "relative",
                              maxWidth: "90%",
                              width: "90%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              borderRadius: "10px",
                            }}
                          />
                        ) : (
                          <h2
                            style={{
                              textAlign: "center",
                              fontFamily: "'Changa', sans-serif",
                            }}
                          >
                            No image available
                          </h2>
                        )}
                      </div>
                      {/* </div> */}
                      <MdDelete
                        className="deleteLocationIcon"
                        onClick={() => deleteLocation(marker._id)}
                      />
                    </div>
                  ))}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="image-dialog-title"
                    PaperProps={{
                      style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        overflow: "hidden",
                      },
                    }}
                  >
                    <img
                      src={currentImage}
                      alt="Expanded view"
                      className="full-image"
                      style={{
                        position: "relative",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                    <CloseIcon
                      onClick={handleClose}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "#343434",
                        cursor: "pointer",
                        fontSize: "2rem",
                      }}
                    />
                  </Dialog>
                </div>
              </div>
            )}
          </div>
        </div>
        <UniversalNotifications
          open={universalErrorMessage}
          message={universalErrorMessageNotification}
          handleClose={handleCloseUniversalErrorMessage}
        />
      </div>
    </div>
  );
};

export default MyLocations;
