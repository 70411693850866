import { motion } from "framer-motion";
import react from "react";
import Button from "@mui/material/Button";
import "../../CSS/ExtremeSports.css";
import { sports } from "../../constants";

const halfIndex = Math.ceil(sports.length / 2);
const sportsTop = sports.slice(0, halfIndex);
const sportsBottom = sports.slice(halfIndex);

const ExtremeSports = () => {
  return (
    <section id="extremeSportListPage">
      <div className="extremeSportListContainer">
        <div className="extremeSportGrid">
          <div className="extremeSportsMotionParts">
            <div className="extremeSportsListRowOne">
              <TranslateWrapper>
                <SportsItemsTop />
              </TranslateWrapper>
              <TranslateWrapper>
                <SportsItemsTop />
              </TranslateWrapper>
              <TranslateWrapper>
                <SportsItemsTop />
              </TranslateWrapper>
            </div>
            <div className="extremeSportsListRowTwo">
              <TranslateWrapper reverse>
                <SportsItemsBottom />
              </TranslateWrapper>
              <TranslateWrapper reverse>
                <SportsItemsBottom />
              </TranslateWrapper>
              <TranslateWrapper reverse>
                <SportsItemsBottom />
              </TranslateWrapper>
            </div>
          </div>
          <div className="extremeheaders">
            <h1 className="extremeSportListHeader" style={{ color: "white" }}>
              <span>You</span> name it.
            </h1>
            <h1 className="extremeSportListHeader" style={{ color: "white" }}>
              <span>We</span> have it.
            </h1>
            <p className="extremeSportListDescription">
              Choose any of the extreme sports below to watch, upload or
              interact with videos in that extreme sport!
            </p>
            <div className="extremeSportListButton">
              <Button
                sx={{
                  color: "#7df9ff",
                  borderRadius: "10px",
                  textAlign: "center",
                  fontFamily: "'Changa', sans-serif",
                  fontWeight: "400",
                  fontSize: "1.2rem",
                  "&:hover": {
                    color: "red",
                  },
                }}
              >
                View All Sports
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const TranslateWrapper = ({ children, reverse }) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? "-100%" : "0%" }}
      animate={{ translateX: reverse ? "0%" : "-100%" }}
      transition={{ duration: 50, repeat: Infinity, ease: "linear" }}
      className="translateWrapper"
    >
      {children}
    </motion.div>
  );
};

const SportItem = ({ sport }) => {
  return (
    <div className="sportItem">
      <p
        style={{
          position: "relative",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        {sport}
      </p>
    </div>
  );
};

const SportsItemsTop = () => (
  <>
    {sportsTop.map((sport, index) => (
      <SportItem key={index} sport={sport} />
    ))}
  </>
);

const SportsItemsBottom = () => (
  <>
    {sportsBottom.map((sport, index) => (
      <SportItem key={index} sport={sport} />
    ))}
  </>
);

export default ExtremeSports;
