import React, { useRef, useEffect } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import "../../CSS/AboutSection.css";

const AboutSection = () => {
  return (
    <div className="aboutSectionPage">
      <HorizontalScrollCarousel />
    </div>
  );
};
export const ScreenFitText = () => {
  const containerRef = useRef(null);
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);

  useEffect(() => {
    const resizeText = () => {
      [textRef1.current, textRef2.current].forEach((text) => {
        if (!containerRef.current || !text) {
          return;
        }

        const containerWidth = containerRef.current.offsetWidth;
        let min = 1;
        let max = 2500;

        while (min <= max) {
          const mid = Math.floor((min + max) / 2);
          text.style.fontSize = `${mid}px`;

          if (text.offsetWidth <= containerWidth) {
            min = mid + 1;
          } else {
            max = mid - 1;
          }
        }

        text.style.fontSize = `${max}px`;
      });
    };

    resizeText();

    window.addEventListener("resize", resizeText);

    return () => {
      window.removeEventListener("resize", resizeText);
    };
  }, []);

  return (
    <div className="maxFontSizeContainer" ref={containerRef}>
      <span ref={textRef1} className="screenFitText">
        <span style={{ color: "#7df9ff" }}>Join</span> the Adventure.
      </span>
      <span ref={textRef2} className="screenFitText">
        <span style={{ color: "#7df9ff" }}>Discover</span> the Extreme.
      </span>
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  return (
    <section ref={targetRef} id="horizontalScrollSection">
      <div className="horizontalScrollContainer">
        <div className="aboutSectionGrid">
          <div className="aboutSectionSlidingTextContainer">
            <motion.div style={{ x }} className="horizontalScrollMotion">
              <ScreenFitText />
            </motion.div>
          </div>
          <div className="aboutTextSection">
            <p>
              Welcome to Adventure Xtreme your ultimate gateway to the world's
              most exhilarating sports community. Here, we don't just push
              limits; we redefine them. Dive into a realm where every jump,
              ride, and wave is a story waiting to be told. Share your epic
              moments, connect with fellow adrenaline seekers, and discover
              locations that beg to be explored. Whether you're here to showcase
              your latest stunt or to draw inspiration from the daring feats of
              others, Adventure Xtreme is where your passion for extreme sports
              becomes a shared adventure. Send It. Share It. Own It. Start your
              journey today.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
