import { AnimatePresence, color, motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import { FaHome, FaMapMarkedAlt, FaTrophy } from "react-icons/fa";
import { GiPodiumWinner } from "react-icons/gi";
import { IoSearch } from "react-icons/io5";
import { RiMenuLine } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";
import { FcAdvertising } from "react-icons/fc";
import promotion from "../../assets/images/promotion.svg";
import promotionHover from "../../assets/images/promotionHover.svg";
import { MdOutlineQuestionMark } from "react-icons/md";

import "../../CSS/MainNavBar.css";

const MainNavBar = ({
  onSelectOption,
  selectedOption,
  isMenuOpen,
  toggleMenu,
}) => {
  return (
    <div className="mainNavBarPage">
      <SideNav
        onSelectOption={onSelectOption}
        selectedOption={selectedOption}
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
      />
    </div>
  );
};

const SideNav = ({
  onSelectOption,
  selectedOption,
  isMenuOpen,
  toggleMenu,
}) => {
  const menuRef = useRef(null);

  const handleSelectOption = (option) => {
    onSelectOption(option);
    toggleMenu(); // Use toggleMenu from props to close the menu after selection
  };

  // Click outside logic
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleMenu(false); // Close the menu if the click is outside
      }
    };

    // Attach the listener to document only if the menu is open
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, toggleMenu]);

  return (
    <div
      ref={menuRef}
      className={`menuWrapper ${isMenuOpen ? "menuActive" : ""}`}
    >
      <div className="menuSliderContainer">
        <div className="menuOptionsBackgroud">
          <div className="allOptions">
            <div
              className={`menuOption ${
                selectedOption === "Home" ? "selected" : ""
              }`}
              onClick={() => onSelectOption("Home")}
            >
              <div
                className="menuOptionContent"
                onClick={() => handleSelectOption("Home")}
              >
                <div className="menuIconContainer">
                  <FaHome className="testingIcon" />
                </div>
                <div className="menuTitleContainer">
                  <span>Home</span>
                </div>
              </div>
            </div>
            <div
              className={`menuOption ${
                selectedOption === "Spots" ? "selected" : ""
              }`}
              onClick={() => onSelectOption("Spots")}
            >
              <div
                className="menuOptionContent"
                onClick={() => handleSelectOption("Spots")}
              >
                <div className="menuIconContainer">
                  <FaMapMarkedAlt className="testingIcon" />
                </div>
                <div className="menuTitleContainer">
                  <span>Spots</span>
                </div>
              </div>
            </div>
            <div
              className={`menuOption ${
                selectedOption === "Promo" ? "selected" : ""
              }`}
              onClick={() => onSelectOption("Promo")}
            >
              <div
                className="menuOptionContent"
                onClick={() => handleSelectOption("Promo")}
              >
                <div className="menuIconContainer">
                  <img src={promotion} alt="Promotion" />
                  <img src={promotionHover} alt="Promotion Hover" />
                </div>
                <div className="menuTitleContainer">
                  <span>Promos</span>
                </div>
              </div>
            </div>
            <div
              className={`menuOption ${
                selectedOption === "H.O.F" ? "selected" : ""
              }`}
              onClick={() => onSelectOption("H.O.F")}
            >
              <div
                className="menuOptionContent"
                onClick={() => handleSelectOption("H.O.F")}
              >
                <div className="menuIconContainer">
                  <GiPodiumWinner className="testingIcon" />
                </div>
                <div className="menuTitleContainer">
                  <span>H.O.F</span>
                </div>
              </div>
            </div>
            <div
              className={`menuOption ${
                selectedOption === "Discover" ? "selected" : ""
              }`}
              onClick={() => onSelectOption("Discover")}
            >
              <div className="menuOptionContent">
                <div
                  className="menuIconContainer"
                  onClick={() => handleSelectOption("Discover")}
                >
                  <MdOutlineQuestionMark className="testingIcon" />
                </div>
                <div className="menuTitleContainer">
                  <span>Discover</span>
                </div>
              </div>
            </div>
            <div
              className={`menuOption ${
                selectedOption === "Search" ? "selected" : ""
              }`}
              onClick={() => onSelectOption("Search")}
            >
              <div className="menuOptionContent">
                <div
                  className="menuIconContainer"
                  onClick={() => handleSelectOption("Search")}
                >
                  <IoSearch className="testingIcon" />
                </div>
                <div className="menuTitleContainer">
                  <span>Search</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menuLine"></div>
        <div className="menuBurger">
          {isMenuOpen ? (
            <MdOutlineCancel
              style={{ cursor: "pointer" }}
              onClick={toggleMenu}
              className="menuBurgerCancel"
            />
          ) : (
            <RiMenuLine
              style={{ cursor: "pointer" }}
              onClick={toggleMenu}
              className="menuBurgerLine"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MainNavBar;
