import React, { useState } from "react";
import ConsentFormContent from "../Components/SignUpConsentForm";
import "../../CSS/SignUpConsentForm.css";
import { Checkbox, Button, FormControlLabel } from "@mui/material";

function SignUpConsentForm() {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleAgreementChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  return (
    <div className="informationSheetLandingPage">
      <div className="fullConsentInformationSheetContainer">
        <ConsentFormContent />
        <div className="agreementContainer">
          <FormControlLabel
            control={
              <Checkbox
                checked={isAgreed}
                onChange={handleAgreementChange}
                color="primary"
              />
            }
            label="I have read and agree to the above information sheet"
          />
          <br></br>
          <Button
            variant="contained"
            color="primary"
            disabled={!isAgreed}
            style={{ marginTop: "20px" }}
          >
            Next Page
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SignUpConsentForm;
