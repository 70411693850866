export const sports = [
  "Skydiving",
  "Rock Climbing",
  "Surfing",
  "Skateboarding",
  "Snowboarding",
  "Bungee Jumping",
  "Base Jumping",
  "Mountain Biking",
  "BMX Racing",
  "Freestyle BMX",
  "White Water Rafting",
  "Kayaking",
  "Kitesurfing",
  "Windsurfing",
  "Parkour",
  "Free Running",
  "Ice Climbing",
  "Scuba Diving",
  "Cave Diving",
  "Highlining",
  "Slacklining",
  "Paragliding",
  "Hang Gliding",
  "Wing Suit Flying",
  "Motocross",
  "Rally Racing",
  "Drift Racing",
  "Mountain Boarding",
  "Sandboarding",
  "Street Luge",
  "Downhill Skateboarding",
  "Extreme Skiing",
  "Freestyle Skiing",
  "Heli-Skiing",
  "Snowkiting",
  "Ice Sailing",
  "Zorbing",
  "Cliff Diving",
  "Extreme Ironing",
  "Pogo Stick Extreme",
  "Free Solo Climbing",
  "Deep-Water Soloing",
  "Speed Flying",
  "Airsoft",
  "Paintball",
  "Bull Riding",
  "Extreme Canyoning",
  "Waterfall Kayaking",
  "Wakeboarding",
  "Jet Skiing",
  "Powerbocking",
  "Aggressive Inline Skating",
  "Scootering",
  "Off-Piste Skiing",
  "Cross-Country Skiing",
  "Adventure Racing",
  "Obstacle Course Racing",
  "Triathlon (Extreme Conditions)",
  "Ultra Marathon",
  "Desert Marathon",
  "Ice Marathon",
  "High Altitude Mountaineering",
  "Volcano Boarding",
  "Whitewater Stand Up Paddleboarding",
  "Trampolining",
];
