import React, { useEffect, useState } from "react";
import "../../CSS/DiscoverFeed.css";
import DiscoverFeedPosts from "./DiscoverFeedPosts";

const DiscoverFeed = ({ selectedSport, difficulty, onNavigateToProfile }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetchVideos();
  }, [selectedSport, difficulty]);

  const fetchVideos = async () => {
    const token = localStorage.getItem("AdventureXtremeUserToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/fetchAllVideos`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sport: selectedSport !== "All" ? selectedSport : undefined,
            difficulty: difficulty !== "All" ? difficulty : undefined,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch videos");
      }

      const data = await response.json();
      setVideos(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "1rem",
        maxHeight: "80vh",
        marginBottom: "2rem",
      }}
    >
      {videos.map((video, index) => (
        <div key={index} className="mainFeedPostContainer">
          <DiscoverFeedPosts
            post={video}
            onNavigateToProfile={onNavigateToProfile}
          />
        </div>
      ))}
    </div>
  );
};

export default DiscoverFeed;
