import React, { useState, useEffect } from "react";
import { TextField, List, ListItem, ListItemText } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "../../assets/images/search.svg";
import BackArrow from "../../assets/images/backArrow.svg";
import BackArrowHover from "../../assets/images/backArrowHover.svg";

import "../../CSS/SearchProfile.css";
const whiteTheme = createTheme({
  typography: {
    fontFamily: "'Changa', sans-serif",
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#fff",
          "&:focus": {
            backgroundColor: "#000b0c",
          },
        },
        icon: {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000b0c",
          ".MuiMenu-list": {
            border: "2px solid",
            borderColor: "#343434",
            borderRadius: "10px",
            fontFamily: "'Changa', sans-serif",
          },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#000b0c",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#343434",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#ffffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#343434",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#343434",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
        },
      },
    },
    // Adding styles for MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #343434",
          borderRadius: "15px",
        },
      },
    },
  },
});

const SearchProfile = ({ onNavigateToProfile }) => {
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [backArrowHover, setBackArrowHover] = useState(false);

  useEffect(() => {
    const fetchUsers = async (searchQuery) => {
      const token = localStorage.getItem("AdventureXtremeUserToken");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/fetchAllUsersByString`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ searchString: searchQuery }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }

        const data = await response.json();
        setUsers(data); // Assuming the response is an array of users
        console.log(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (query.trim()) {
      const timerId = setTimeout(() => {
        fetchUsers(query.trim());
      }, 500); // Debounce the API call

      return () => clearTimeout(timerId); // Clear the timeout if the component unmounts or the query changes
    } else {
      setUsers([]); // Clear users if the query is empty
    }
  }, [query]);

  const handleUsernameClick = (username) => {
    if (onNavigateToProfile) onNavigateToProfile("Profile", username);
  };
  const handleBackButton = () => {
    if (onNavigateToProfile) onNavigateToProfile("Home");
  };
  return (
    <ThemeProvider theme={whiteTheme}>
      <div
        className="backButton"
        onMouseEnter={() => setBackArrowHover(true)}
        onMouseLeave={() => setBackArrowHover(false)}
        onClick={handleBackButton} // Assuming you want the whole area clickable
        style={{ cursor: "pointer" }}
      >
        {" "}
        {/* Ensure the cursor changes to pointer */}
        <img src={backArrowHover ? BackArrowHover : BackArrow} alt="Back" />
      </div>
      <div className="searchContainer">
        <div className="searchComponents">
          <div className="searchIconContainer">
            <img src={SearchIcon} alt="Search" className="searchSymbol" />
          </div>
          <div className="searchBarWrapper">
            <TextField
              label="Search Users"
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{
                width: "calc(100% - 50px)", // Adjusted width
                marginLeft: "10px", // Space between icon and input field
              }}
              fullWidth
            />
          </div>
        </div>
        <List
          sx={{
            bgcolor: "transparent",
            color: "text.primary",
            padding: 0,
            margin: 0,
          }}
        >
          {users.map((user) => (
            <ListItem
              key={user.id}
              button
              onClick={() => handleUsernameClick(user.username)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
                border: "3px solid #343434",
                borderRadius: "10px",
                height: "10rem",
                padding: "0 10px",
                width: "80%",
                left: "10%",
                transition: "transform 0.3s ease",
                cursor: "pointer",
                ":hover": {
                  transform: "scale(1.05)",
                },
                "@media (max-width: 768px)": {
                  height: "auto",
                  padding: "10px",
                },
              }}
            >
              {user.profilePicture ? (
                <div className="searchImageWrapper">
                  <img
                    src={user.profilePicture}
                    alt={`${user.username}'s profile`}
                  />
                </div>
              ) : (
                <div className="searchImageWrapper" />
              )}
              <ListItemText
                primary={user.username}
                primaryTypographyProps={{
                  variant: "body1",
                  sx: {
                    flex: 1,
                    marginLeft: { xs: 0, sm: "20px" }, // Adjust margin for smaller screens
                    textAlign: { xs: "center", sm: "left" }, // Center text on small screens, left align on larger screens
                    fontSize: { xs: "1.2rem", sm: "1.5rem" }, // Responsive font size
                    marginTop: { xs: "10px", sm: 0 }, // Adds space between the image and text on small screens
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </ThemeProvider>
  );
};

export default SearchProfile;
