import { motion } from "framer-motion";
import { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useMeasure from "react-use-measure";
import "../../CSS/FeatureCarousel.css";
import videoSharing from "../../assets/images/featureVideoSharing.png";
import locationSharing from "../../assets/images/featureLocationSharing.png";
import sponsorships from "../../assets/images/featureSponsorships.png";
import community from "../../assets/images/featureCommunity.png";
import competition from "../../assets/images/featureCompetition.png";

const CARD_WIDTH = 350;
const CARD_HEIGHT = 350;
const MARGIN = 20;
const BREAKPOINTS = { sm: 640, lg: 1024 };

// Dynamically calculate card size
const calculateCardSize = (containerWidth) => {
  if (containerWidth < BREAKPOINTS.sm) {
    return containerWidth - 40; // Adjust for smaller screens
  } else {
    return 350; // Default card width for larger screens
  }
};

// Inside your component, calculate based on the measured width
const dynamicCardWidth = calculateCardSize(CARD_WIDTH);

const FeatureCarousel = () => {
  const [ref, { width }] = useMeasure();
  const [offset, setOffset] = useState(0);

  const dynamicCardWidth = calculateCardSize(width);
  const dynamicCardSize = dynamicCardWidth + MARGIN;

  const CARD_BUFFER =
    width > BREAKPOINTS.lg ? 3 : width > BREAKPOINTS.sm ? 2 : 1;

  const CAN_SHIFT_LEFT = offset < 0;
  const CAN_SHIFT_RIGHT =
    Math.abs(offset) < dynamicCardSize * (items.length - CARD_BUFFER);

  const shiftLeft = () => {
    if (!CAN_SHIFT_LEFT) return;
    setOffset((prevOffset) => prevOffset + dynamicCardSize);
  };

  const shiftRight = () => {
    if (!CAN_SHIFT_RIGHT) return;
    setOffset((prevOffset) => prevOffset - dynamicCardSize);
  };

  return (
    <section id="featureCarouselPage" ref={ref}>
      <div className="featureCarouselContainer">
        {/* CARDS */}
        <div className="featureCarouselCards">
          <motion.div
            animate={{
              x: offset,
            }}
            className="slidingEffect"
          >
            {items.map((item) => {
              return (
                <Card key={item.id} cardWidth={dynamicCardWidth} {...item} />
              );
            })}
          </motion.div>
        </div>

        {/* BUTTONS */}
        <>
          <motion.button
            initial={false}
            animate={{
              x: CAN_SHIFT_LEFT ? "0%" : "-100%",
            }}
            className="featureCarouselButtonLeft"
            onClick={shiftLeft}
          >
            <FiChevronLeft />
          </motion.button>
          <motion.button
            initial={false}
            animate={{
              x: CAN_SHIFT_RIGHT ? "0%" : "100%",
            }}
            className="featureCarouselButtonRight"
            onClick={shiftRight}
          >
            <FiChevronRight />
          </motion.button>
        </>
      </div>
    </section>
  );
};

const Card = ({ url, category, title, description, cardWidth }) => {
  return (
    <div
      className="featureCardContainer"
      style={{
        width: cardWidth,
        height: CARD_HEIGHT, // Consider making this dynamic too
        marginRight: MARGIN,
        backgroundImage: `url(${url})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="featureCardContent">
        <p>{category}</p>
        <div className="featureCardTitle" style={{ width: "100%" }}>
          <p>{title}</p>
        </div>
        <div className="featureCardDescription" style={{ width: "100%" }}>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCarousel;

const items = [
  {
    id: 1,
    url: videoSharing,
    category: "Adventure Xtreme",
    title: "Video Sharing",
    description:
      "Share your epic videos to a community of people that understands",
  },
  {
    id: 2,
    url: locationSharing,
    category: "Adventure Xtreme",
    title: "Locations",
    description:
      "Want to share a location but don't want the natural beauty ruined? Share locations with people only you trust.",
  },
  {
    id: 3,
    url: sponsorships,
    category: "Adventure Xtreme",
    title: "Sponsorships",
    description:
      "Exclusive portal for companies to see profiles of our users to give them the best chance of getting sponsored",
  },
  {
    id: 4,
    url: community,
    category: "Adventure Xtreme",
    title: "Community",
    description: "Connect with like-minded people around the world",
  },
  {
    id: 5,
    url: competition,
    category: "Adventure Xtreme",
    title: "Weekly Competitions",
    description:
      "Upload videos for a chance to earn your spot in the Hall of Fame for a winning stunt. Everyone has an equal chance at winning, not just the big accounts!",
  },
];
