import React, { useState, useEffect } from "react";
import "../../CSS/DashboardHome.css";
import { sports } from "../../constants";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import DiscoverFeed from "./DiscoverFeed";
import DiscoverFeedPosts from "./DiscoverFeedPosts";
import BackArrow from "../../assets/images/backArrow.svg";
import BackArrowHover from "../../assets/images/backArrowHover.svg";

const whiteTheme = createTheme({
  typography: {
    fontFamily: "'Changa', sans-serif",
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#fff",
          "&:focus": {
            backgroundColor: "#000b0c",
          },
        },
        icon: {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000b0c",
          ".MuiMenu-list": {
            border: "2px solid",
            borderColor: "#343434",
            borderRadius: "10px",
            fontFamily: "'Changa', sans-serif",
          },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#000b0c",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#343434",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#ffffff",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            backgroundColor: "#343434",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#343434",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          position: "relative",
          width: "35%", // Takes up 70% of the parent width
          height: "100%", // Takes up 100% of the parent height
          maxHeight: "7vh",
          padding: "0",
          margin: "0",
        },
      },
    },
    // Adding styles for MuiDialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #343434",
          borderRadius: "15px",
        },
      },
    },
  },
});
const DashboardHome = (onNavigateToProfile) => {
  const [selectedSport, setSelectedSport] = useState("all");
  const [difficulty, setDifficulty] = useState("all");
  const [posts, setPosts] = useState([]);
  const [backArrowHover, setBackArrowHover] = useState(false);

  const fetchPostsByCriteria = async () => {
    const token = localStorage.getItem("AdventureXtremeUserToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/fetchAllPostsByCriteria`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sport: selectedSport !== "all" ? selectedSport : undefined,
            difficulty: difficulty !== "all" ? difficulty : undefined,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch posts");
      }

      const data = await response.json();
      setPosts(data); // Assuming you have this state to store the fetched data
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Use useEffect to fetch posts when the selectedSport or difficulty changes
  useEffect(() => {
    fetchPostsByCriteria();
  }, [selectedSport, difficulty]);

  useEffect(() => {
    fetchPostsByCriteria();
  }, [selectedSport, difficulty]);

  const handleSportChange = (event) => {
    setSelectedSport(event.target.value);
  };

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };
  const handleBackButton = () => {
    if (onNavigateToProfile) onNavigateToProfile("Home");
  };
  return (
    <div>
      <div
        className="backButton"
        onMouseEnter={() => setBackArrowHover(true)}
        onMouseLeave={() => setBackArrowHover(false)}
        onClick={handleBackButton} // Assuming you want the whole area clickable
        style={{ cursor: "pointer" }}
      >
        {" "}
        {/* Ensure the cursor changes to pointer */}
        <img src={backArrowHover ? BackArrowHover : BackArrow} alt="Back" />
      </div>
      <div className="dashboardHomePage">
        <div className="dashboardHomeContentContainer">
          <div className="homeFilteringSection">
            <ThemeProvider theme={whiteTheme}>
              <FormControl
                sx={
                  {
                    // Ensuring it doesn't exceed the container's height
                  }
                }
              >
                <InputLabel>Sport</InputLabel>
                <Select
                  value={selectedSport}
                  label="Sport"
                  onChange={handleSportChange}
                  sx={{ height: "100%" }}
                >
                  {[
                    { value: "all", label: "All Sports" },
                    ...sports
                      .sort()
                      .map((sport) => ({ value: sport, label: sport })),
                  ].map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{}}>
                <InputLabel>Difficulty</InputLabel>
                <Select
                  value={difficulty}
                  label="Difficulty"
                  onChange={handleDifficultyChange}
                  sx={{ height: "100%" }}
                >
                  {[
                    { value: "all", label: "All Difficulties" },
                    ...[1, 2, 3, 4, 5].map((level) => ({
                      value: level.toString(),
                      label: level,
                    })),
                  ].map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ThemeProvider>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: "1rem",
              maxHeight: "80vh",
              marginBottom: "2rem",
            }}
          >
            {posts.map((post) => (
              <div key={post._id} className="post">
                <DiscoverFeedPosts
                  post={post}
                  onNavigateToProfile={onNavigateToProfile}
                />
              </div>
            ))}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
