import React from "react";
import "../../CSS/Footer.css";

function Footer() {
  return (
    <div className="footerComponent">
      <div className="footerGrid">
        <div className="footerHeader">
          <h1>Adventure Xtreme</h1>
        </div>
        <div className="footerOptions">
          <div className="footerOptionOne">
            <span>About Us</span>
          </div>
          <div className="footerOptionTwo">
            <span>Contact Us</span>
          </div>
          <div className="footerOptionThree">
            <span>T's & C's</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
