// SteppedProgress.jsx
import React, { useState, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TextField, Button } from "@mui/material";
import "../../CSS/SteppedProgress.css";
import SignUpConsentForm from "./SignUpConsentForm";
import blankProfile from "../../assets/images/blankProfile.png";
import SportsChecklist from "./SportsChecklist";
import SocialMediaForm from "./SocialMediaForm";
import { useNavigate } from "react-router-dom";

const SteppedProgressEditProfile = () => {
  const [stepsComplete, setStepsComplete] = useState(0);
  const numSteps = 4;

  const [bio, setBio] = useState("");
  const [profilePic, setProfilePic] = useState(blankProfile); // Replace 'blankProfile' with the path to your initial profile picture.

  const [selectedSports, setSelectedSports] = useState([]);

  const [socialLinks, setSocialLinks] = useState({
    instagram: "",
    tiktok: "",
    youtube: "",
  });

  const navigate = useNavigate();

  const validateInstagramUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_\.]+/.test(url)
    );
  };

  const validateYoutubeUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?[a-zA-Z0-9\-_]+/.test(
        url
      )
    );
  };

  const validateTiktokUrl = (url) => {
    return (
      url === "" ||
      /(?:http(s)?:\/\/)?(www\.)?tiktok\.com\/@?[a-zA-Z0-9\-_]+/.test(url)
    );
  };

  const validateField = (name, value) => {
    switch (name) {
      case "instagram":
        return validateInstagramUrl(value);
      case "youtube":
        return validateYoutubeUrl(value);
      case "tiktok":
        return validateTiktokUrl(value);
      default:
        return true;
    }
  };

  const [socialErrors, setSocialErrors] = useState({
    instagram: false,
    tiktok: false,
    youtube: false,
  });

  // Function to update the error state, which you'll pass down to SocialMediaForm
  const updateSocialErrors = (errors) => {
    setSocialErrors(errors);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setProfilePic(e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleAgreeToConsent = async () => {
    const token = localStorage.getItem("AdventureXtremeUserToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/agreeToConsentForm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        handleSetStep(1);
      } else {
        throw new Error("Server responded with a non-200 status code");
      }
    } catch (error) {
      console.error("Error submitting consent agreement:", error);
    }
  };

  const handleProfileSubmit = async (event) => {
    const file = document.querySelector('input[type="file"]').files[0]; // Adjust if your file input selector differs
    const bioText = bio; // Assuming 'bio' is the state variable holding the bio text

    const formData = new FormData();
    formData.append("profilePic", file);
    formData.append("bio", bioText);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/updateProfile`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "AdventureXtremeUserToken"
          )}`,
        },
        body: formData, // Send the form data
      }
    );

    if (response.ok) {
      const result = await response.json();
      console.log("Server response:", result);
      // Handle success (e.g., notifying the user, updating the UI)
    } else {
      // Handle errors (e.g., displaying an error message)
      const error = await response.text();
      console.error("Error updating profile:", error);
    }
  };

  const handleSportChoices = async () => {
    console.log("Submitting selected sports: ", selectedSports);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/submitSportsChoices`,
        {
          // Adjust the URL as needed
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "AdventureXtremeUserToken"
            )}`, // Assuming you store the token in localStorage
          },
          body: JSON.stringify({ selectedSports }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Success! ", result.message);
      } else {
        throw new Error("Failed to submit sports choices");
      }
    } catch (error) {
      console.error("Error submitting sports choices:", error);
    }
  };

  const handleSubmitSocialMediaLinks = async () => {
    const token = localStorage.getItem("AdventureXtremeUserToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/updateSocialMediaLinks`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            instagram: socialLinks.instagram,
            tiktok: socialLinks.tiktok,
            youtube: socialLinks.youtube,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        // handle any post-update logic here, like navigating to another page or showing a success message
      } else {
        throw new Error("Server responded with a non-200 status code");
      }
    } catch (error) {
      console.error("Error updating social media links:", error);
    }
  };

  const handleSetStep = (num) => {
    if (
      (stepsComplete === 0 && num === -1) ||
      (stepsComplete === numSteps && num === 1)
    ) {
      return;
    }
    setStepsComplete((pv) => pv + num);
  };

  const handleInputChange = (setter) => (event) => {
    const newValue = event.target.value;
    setter(newValue);
  };

  return (
    <div className="steppedProgressPage">
      <div className="steppedProgressContainer">
        <Steps numSteps={numSteps} stepsComplete={stepsComplete} />

        <div className="steppedProgressCardArea">
          {stepsComplete === 0 && (
            <div>
              <div className="divB">
                <SignUpConsentForm />
                <div className="steppedProgressButtons">
                  <Button
                    className="steppedProgressPrevButton"
                    type="submit"
                    onClick={() => {
                      handleSetStep(-1);
                    }}
                    sx={{
                      color: "white",
                      border: "1px solid #7df9ff",
                      transition: "0.3s ease-in-out",
                      display: "none",
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "0.3s ease-in-out",
                      },
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    className="steppedProgressNextButton"
                    onClick={handleAgreeToConsent}
                    sx={{
                      color: "white",
                      border: "1px solid #7df9ff",
                      transition: "0.3s ease-in-out",
                      fontFamily: "'Changa',sans-serif",
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "0.3s ease-in-out",
                      },
                    }}
                  >
                    I agree, continue
                  </Button>
                </div>
              </div>
            </div>
          )}
          {stepsComplete === 1 && (
            <div id="divC">
              <div className="addPPandBiogrid">
                <div className="profilePic">
                  <div className="profilePicContainer">
                    <img src={profilePic} alt="" />
                  </div>
                  <div className="uploadButton">
                    <input
                      accept="image/*"
                      type="file"
                      id="select-image"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    <label htmlFor="select-image">
                      <Button
                        component="span"
                        sx={{
                          fontFamily: "'Changa', sans-serif",
                          textAlign: "center",
                          color: "white",
                          border: "1px solid #7df9ff",
                          transition: "0.3s ease-in-out",
                          left: "50%",
                          transform: "translateX(-50%)",
                          "&:hover": {
                            transform: "scale(1.1) translateX(-45%)",
                            transition: "0.3s ease-in-out",
                          },
                        }}
                      >
                        Choose From File
                      </Button>
                    </label>
                  </div>
                </div>
                <div className="bio">
                  <span>
                    <h1>Build Your Profile</h1>
                  </span>
                  <TextField
                    id="outlined-multiline-static"
                    label="Your Bio"
                    multiline
                    value={bio}
                    onChange={handleInputChange(setBio)}
                    rows={4}
                    variant="outlined"
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    InputProps={{
                      style: { color: "#fff" },
                    }}
                    sx={{
                      width: "80%",
                      left: "15%",
                      top: "10%",
                      transform: "translateY(-10%)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#fff",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#fff",
                      },
                      "& .Mui-focused .MuiInputLabel-root": {
                        color: "#fff",
                      },
                      "& label.Mui-focused": {
                        color: "#fff",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="steppedProgressButtons">
                <Button
                  className="steppedProgressPrevButton"
                  type="submit"
                  onClick={() => {
                    handleSetStep(1);
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                  }}
                >
                  Skip
                </Button>
                <Button
                  className="steppedProgressNextButton"
                  onClick={() => {
                    handleSetStep(1);
                    handleProfileSubmit();
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                    "&.Mui-disabled": {
                      borderColor: "grey",
                      color: "grey",
                      opacity: 0.5,
                    },
                  }}
                  disabled={!profilePic || !bio}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {stepsComplete === 2 && (
            <div id="divD">
              <h2
                style={{
                  textAlign: "center",
                  color: "#7df9ff",
                  fontFamily: "'Changa', sans-serif",
                  fontWeight: "400",
                }}
              >
                Choose the sports that you're interested in!
              </h2>
              <SportsChecklist
                selectedSports={selectedSports}
                onSelectSport={setSelectedSports}
              />
              <div className="steppedProgressButtons">
                <Button
                  className="steppedProgressPrevButton"
                  type="submit"
                  onClick={() => {
                    handleSetStep(1);
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                  }}
                >
                  Skip
                </Button>
                <Button
                  className="steppedProgressNextButton"
                  onClick={() => {
                    handleSetStep(1);
                    handleSportChoices();
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                    "&.Mui-disabled": {
                      borderColor: "grey",
                      color: "grey",
                      opacity: 0.5,
                    },
                  }}
                  disabled={selectedSports.length === 0}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {stepsComplete === 3 && (
            <div className="SocialMediaForm">
              <SocialMediaForm
                socialLinks={socialLinks}
                setSocialLinks={setSocialLinks}
                validateField={validateField}
                updateErrors={updateSocialErrors} // Passing the function to update parent's state
              />
              <div className="steppedProgressButtons">
                <Button
                  className="steppedProgressPrevButton"
                  type="submit"
                  onClick={() => {
                    handleSetStep(1);
                    navigate("/Dashboard");
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                  }}
                >
                  Skip
                </Button>
                <Button
                  className="steppedProgressNextButton"
                  onClick={() => {
                    handleSetStep(1);
                    handleSubmitSocialMediaLinks();
                    navigate("/Dashboard");
                  }}
                  sx={{
                    color: "white",
                    border: "1px solid #7df9ff",
                    transition: "0.3s ease-in-out",
                    fontFamily: "'Changa', sans-serif",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "0.3s ease-in-out",
                    },
                    "&.Mui-disabled": {
                      borderColor: "grey",
                      color: "grey",
                      opacity: 0.5,
                    },
                  }}
                  disabled={
                    (!socialLinks.instagram &&
                      !socialLinks.tiktok &&
                      !socialLinks.youtube) ||
                    Object.values(socialErrors).some(Boolean)
                  }
                >
                  Finish
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Steps = ({ numSteps, stepsComplete }) => {
  const stepArray = Array.from(Array(numSteps).keys());

  return (
    <div className="stepsContainer">
      {stepArray.map((num) => {
        const stepNum = num + 1;
        const isActive = stepNum <= stepsComplete;
        return (
          <React.Fragment key={stepNum}>
            <Step num={stepNum} isActive={isActive} />
            {stepNum !== numSteps && (
              <div className="stepsContent">
                <motion.div
                  className="stepsMotion"
                  initial={{ width: 0 }}
                  animate={{ width: isActive ? "100%" : 0 }}
                  transition={{ ease: "easeIn", duration: 0.3 }}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Step = ({ num, isActive }) => {
  return (
    <div className="stepContainer">
      <div
        className={`stepCircleArea ${
          isActive ? "stepCircleAreaIsActive" : "stepCircleAreaNotActive"
        }`}
      >
        <AnimatePresence mode="wait">
          {isActive ? (
            <motion.svg
              key="icon-marker-check"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="stepAnimationClass"
            >
              <path
                fillRule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </motion.svg>
          ) : (
            <motion.span
              key="icon-marker-num"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="stepAnimationClass"
            >
              {num}
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      {isActive && <div className="pulse"></div>}
    </div>
  );
};

export default SteppedProgressEditProfile;
